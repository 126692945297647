import { Box, TextField, Typography } from '@mui/material';

const PermissionsSearchBar = ({user, setsearchCriteria}: any) => {
	return (
		<Box sx={{height: '100px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 50px'}}>
			<Typography variant="h4">{user.name}'s Permissions</Typography>
			<TextField
				id="outlined-basic"
				variant="outlined"
				fullWidth
				label="Search"
				sx={{width: '300px'}}
				onChange={e => setsearchCriteria(e.target.value)}
			/>
		</Box>
	);
};

export default PermissionsSearchBar;