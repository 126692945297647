//Node Modules
import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { logoutUser, setUser } from '../redux/userReducer';
import { useSnackbar } from 'notistack';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

const serverURL = process.env.REACT_APP_API_URL;
export interface FetchState<T = any> {
	data: T;
	success: boolean;
	error: string | null;
	loading: boolean;
}

const initState: FetchState = {
	data: null,
	success: false,
	error: null,
	loading: true,
};

export const buildFetch = (payload: any, passJWT: boolean, jwt: any, isPost: boolean) => {
	let baseHeaders = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	};

	const authHeaders = {
		...baseHeaders,
		Authorization: 'Bearer ' + jwt,
	};

	const headers = passJWT ? authHeaders : baseHeaders;

	return {
		method: isPost ? 'POST' : 'GET',
		headers,
		body: isPost ? JSON.stringify(payload) : null,
	};
};

export function usePost<T = any>(url: string, passJWT: boolean, triggers: any[] | null = null, payload: any): FetchState<T> {
	return useFetch<T>(url, passJWT, triggers, payload, true);
}

const BASE_SERVER_URL = process.env.REACT_APP_API_URL;

export function useFetch<T = any>(
	url: string,
	passJWT: boolean = true,
	triggers: any[] | null = null,
	payload: any = null,
	isPost: boolean = false,
	isRenewal: boolean = false,
): FetchState<T> {
	const isMounted = useRef(true);
	const [state, setState] = useState(initState);
	const { jwt, refreshToken, internalId } = useSelector((state: any) => state.user);
	const fullURL = `${BASE_SERVER_URL}${url}`;
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const { logout } = useAuth0();
	const navigate = useNavigate();


	useEffect(
		() => {
			setState(initState);

			const call = async () => {
				try {
					let response;
					let json;

					response = await fetch(fullURL, buildFetch(payload, passJWT, jwt, isPost));

					if (response.status === 401) {
						try {
							//Refresh JWT
							// const refresh = await fetch(`${serverURL}/auth`, buildFetch({}, true, refreshToken, true));

							const refresh = await fetch(
								`${serverURL}/auth/refresh`,
								buildFetch(
									{
										userId: internalId,
									},
									true,
									refreshToken,
									true,
								),
							);

							// eslint-disable-next-line
							if (refresh.status === 401) throw 'Refresh Failed';

							const refreshJson = await refresh.json();
							//Update Tokens
							dispatch(
								setUser({
									jwt: refreshJson.token,
									refreshToken: refreshJson.refreshToken,
									isAdmin: refreshJson.isAdmin,
									internalId: refreshJson.internalId,
								}),
							);

							//Retry Original Call
							response = await fetch(fullURL, buildFetch(payload, passJWT, refreshJson.token, isPost));
						} catch (e) {
							enqueueSnackbar(`Status ${response.status}`, { variant: 'error' });
							dispatch(logoutUser());
							logout({ returnTo: window.location.origin });
						}
					}

					if (response.status === 403) {
						enqueueSnackbar(`You do not have access to this resource.`, { variant: 'error' });
						navigate(-1);
						return;
					}

					json = await response.json();

					if (isMounted.current) {
						setState({
							data: json,
							success: response.status < 400,
							error: response.status < 400 ? null : `${response.status}: ${response.statusText}`,
							loading: false,
						});
					}
					if (response.status > 399) {
						enqueueSnackbar(json.RetDet.message || `Status ${response.status}`, { variant: 'error' });
					}
				} catch (e) {
					dispatch(logoutUser());
					logout({ returnTo: window.location.origin });
				}
			};

			call().then();
		},
		// eslint-disable-next-line
		triggers ? [fullURL, ...triggers] : [fullURL],
	);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	return state;
}
