import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { setUser } from '../../../redux/userReducer';
import { Loading } from './Loading';
import { login } from '../../../services/api/login';

export const Login = () => {
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const { getAccessTokenSilently, isLoading } = useAuth0();
	const [finished, setFinished] = useState<boolean>(false);

	const loginCallback = (data: any): void => {
		dispatch(
			setUser({
				jwt: data.token,
				internalId: data.internalId,
				isAdmin: data.isAdmin,
				refreshToken: data.refreshToken,
			}),
		);
		setFinished(true);
	};
	const unauthorizedCallback = () => {
		setFinished(true);
	};

	useEffect(() => {
		(async () => {
			try {
				const token = await getAccessTokenSilently({
					audience: process.env.REACT_APP_AUTH0_AUDIENCE,
					scope: 'openid email profile',
				});

				const response = await fetch(`https://${process.env.REACT_APP_AUTH0_DOMAIN}/userinfo`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				const json = await response.json();

				await login(token, json, unauthorizedCallback, loginCallback);
			} catch (error: any) {
				enqueueSnackbar(error.message || 'Logout Failed', { variant: 'error' });
			}
		})();
		// eslint-disable-next-line
	}, []);

	return isLoading ? <Loading /> : finished ? <Navigate to={'/'} /> : <Loading info={true} />;
};
