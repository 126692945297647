import React from 'react';
import { TextField } from '@mui/material';

interface SettingOption {
	key: string;
	value: string | null;
	id?: number;
}

const Field = (props: { option: SettingOption; edit: boolean; formik: any }): React.ReactElement => {
	const { option, edit, formik } = props;

	return (
		<TextField
			sx={{ marginBottom: '20px' }}
			disabled={!edit}
			key={`field-key-${option.key}`}
			label={option.key}
			value={formik.values[option.key] ?? ''}
			onChange={formik.handleChange(option.key)}
			error={formik.touched[option.key] && Boolean(formik.errors[option.key])}
			helperText={formik.touched[option.key] && formik.errors[option.key]}
		></TextField>
	);
};

export default Field;
