import React, { useEffect, useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddSettingForm from './AddSettingForm';
import { KVPOption } from '../types';
import { Fab } from '@mui/material';

export const AddNewSetting = (props: { edit: boolean; settings: KVPOption[]; setSettings: any }): React.ReactElement => {
	const { edit, settings, setSettings } = props;

	const [open, setOpen] = useState<boolean>(false);

	const handleClick = () => {
		setOpen(!open);
	};

	useEffect(() => {
		setOpen(false);
	}, [edit]);

	return (
		<div style={{ width: '50%' }}>
			{edit ? (
				<Fab
					sx={{ position: 'absolute', left: '2%' }}
					onClick={handleClick}
					type="button"
					color="secondary"
					aria-label="save"
				>
					<AddCircleOutlineIcon />
				</Fab>
			) : null}
			{edit && open && (
				<div>
					<AddSettingForm settings={settings} setSettings={setSettings} />
				</div>
			)}
		</div>
	);
};
