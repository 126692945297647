import ReactDOM from 'react-dom';
import { App } from './App';
import { Auth0Provider } from './modules/Auth0Provider/Auth0Provider';
import { EnvProvider } from './context/env.context';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { PersistGate } from 'redux-persist/integration/react';
import { SnackbarProvider } from 'notistack';

export const theme = createTheme({
	palette: {
		primary: {
			main: '#161616',
			contrastText: '#93DA49',
		},
		secondary: {
			main: '#93DA49',
		},
	},
});

const { store, persistor } = configureStore();

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<ThemeProvider theme={theme}>
				<CssBaseline>
					<SnackbarProvider hideIconVariant>
						<EnvProvider>
							<Auth0Provider>
								<App />
							</Auth0Provider>
						</EnvProvider>
					</SnackbarProvider>
				</CssBaseline>
			</ThemeProvider>
		</PersistGate>
	</Provider>,
	document.getElementById('root'),
);

reportWebVitals();
