import { Card, CardContent, Typography, CardActions, Select, MenuItem } from '@mui/material';
import { useState } from 'react';

const PermissionsBar = ({entity, permission, permGroup, handlePermissionChange}: any) => {
	const [permissionValue, setPermissionValue] = useState<string>(permission);
	const [edited, setEdited] = useState<Boolean>(false);


	const changePermission = (value: string) => {
		setPermissionValue(value);
		setEdited(value !== permission);
		handlePermissionChange({entity, permission, value, permGroup});
	}

	return (
		<Card
			sx={{
				width: '100%',
				minHeight: '75px',
				justifyContent: 'space-between',
				margin: '5px', 
				textTransform: 'capitalize',
				position: 'relative',
				display: 'flex',
				background: edited ? '#ADD8E6' : 'white'
			}}
		>
			<CardContent sx={{ paddingTop: '10px', paddingBottom: '10px' }}>
				<Typography variant="h6" sx={{ alignSelf: 'flex-start' }}>
					{entity.name}
				</Typography>
			</CardContent>

			<CardActions 
				sx={{
					position: 'absolute',
					bottom: 0,
					right: 0
				}}
			>
				<Typography variant="body2" sx={{ alignSelf: 'center', marginRight: '10px' }}>
					Permissions Level: 
				</Typography>
				<Select
					sx={{ alignSelf: 'flex-end', width: '100px', height: '35px', fontSize: '12px' }}
					value={permissionValue}
					onChange={e => changePermission(e.target.value)}
					MenuProps={{
						disableScrollLock: true,
					}}
				>
					<MenuItem value={'read'}>Read</MenuItem>
					<MenuItem value={'write'}>Write</MenuItem>
				</Select>
			</CardActions>
		</Card>
	);
};

export default PermissionsBar;
