import * as yup from 'yup';

const feedbackFilterValidationSchema = yup.object().shape({
	authorEmail: yup.string(),
	reviewDate: yup.string(),
	allowedStatus: yup.array().of(yup.string()),
	minRating: yup.number().min(0).max(5).integer(),
});

export default feedbackFilterValidationSchema;
