//Node Modules
import { useSelector } from 'react-redux';
import { useState } from 'react';

//Material
import { Box } from '@mui/material';

//Internal
import SearchBar from '../Global/components/SearchBar';
import EntityList from '../Global/components/EntityList';
import CreateRootEntityDialog from './components/CreateRootEntityDialog';
import RootEntityOverviewActions from './components/RootEntityOverviewActions';
import CreateRootEntitySubmission from './components/CreateRootEntitySubmission';
import { debounce } from '../../utils/common/debounce';

const Home = () => {
	const user = useSelector((state: any) => state.user);
	const [searchCriteria, setsearchCriteria] = useState<string>('');

	const [open, setOpen] = useState(false);
	const [newEntityName, setNewEntityName] = useState<string>('');
	const [newCrmId, setNewCrmId] = useState<string>('');
	const [submittingCreation, setSubmittingCreation] = useState<Boolean>(false);
	const [reRender, setReRender] = useState<Boolean>(false);

	const updateSearchCriteria = debounce(setsearchCriteria);
	const updateNewEntityName = debounce(setNewEntityName);
	const updateNewCrmId = debounce(setNewCrmId);

	const resetComponent = () => {
		setReRender(!reRender);
		setSubmittingCreation(false);
		setOpen(false);
	};

	const submitEntity = () => {
		setOpen(false);
		setTimeout(() => setSubmittingCreation(true), 1000);
	};

	return (
		<Box sx={{display: 'flex', flexDirection: 'column', flex: '1'}}>
			<SearchBar home={true} setsearchCriteria={updateSearchCriteria}/>
			<EntityList entityId={user.internalId} searchCriteria={searchCriteria}/>
			<CreateRootEntityDialog
				open={open}
				setOpen={setOpen}
				setNewCrmId={updateNewCrmId}
				setNewEntityName={updateNewEntityName}
				submitEntity={submitEntity}
			/>
			<RootEntityOverviewActions createEntity={() => setOpen(true)} />
			{submittingCreation ? (
				<CreateRootEntitySubmission resetComponent={resetComponent} name={newEntityName} crmId={newCrmId} />
			) : null}
		</Box>
	);
};

export default Home;