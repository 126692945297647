import React from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const PermissionActions = ({pendingChanges, setSubmitting, resetComponent, createPermission} : any): React.ReactElement => {
	return (
		<>
			{pendingChanges.length > 0 ? (
				<Fab
					onClick={resetComponent}
					sx={{ position: 'fixed', bottom: '20px', right: '20px' }}
					color="primary"
					aria-label="edit"
				>
					{<CancelIcon />}
				</Fab>
			) : null}
			{pendingChanges.length > 0 ? (
				<Fab 
					sx={{ position: 'fixed', bottom: '20px', right: '85px' }} 
					type="submit" 
					color="secondary" 
					aria-label="save"
					onClick={() => setSubmitting(true)}
				>
					<SaveIcon />
				</Fab>
			) : null}
			<Fab onClick={createPermission} sx={{ position: 'fixed', bottom: '20px', right: '20px' }} color="primary" aria-label="edit">
				<AddIcon />
			</Fab>
		</>
	);
};

export default PermissionActions;