import React from 'react';
import { Auth0Provider as DefaultAuth0Provider } from '@auth0/auth0-react';
import { useEnv } from '../../context/env.context';

export const Auth0Provider = (props: { children: React.ReactNode }) => {
	const context = useEnv();
	const { children } = props;
	return (
		<DefaultAuth0Provider
			domain={context.domain}
			clientId={context.clientId}
			audience={context.audience}
			client_secret={context.secret}
			redirectUri={`${context.site}/auth0`}
		>
			{children}
		</DefaultAuth0Provider>
	);
};
