import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

export const BackButton = (props: { buttonText?: string }): React.ReactElement => {
	const { buttonText } = props;
	let navigate = useNavigate();

	const handleClick = () => {
		navigate(-1);
	};

	return <Button onClick={handleClick}>{buttonText || 'Back'}</Button>;
};
