//Material
import { makeStyles } from '@mui/styles';

//Constants
const navWidth = 250;
const navClosedWidth = 69;

export const GlobalStyles = makeStyles((theme) => ({
	appContent: {
		display: 'flex',
		flexGrow: 1,
		backgroundColor: '#f7f7f7',
		marginTop: '64px',
		minHeight: 'calc(100vh - 64px)',
	},
}));

export const AppBarStyles = makeStyles((theme) => ({
	appBar: {
		zIndex: theme.zIndex + 1,
		height: '64px',
	},
	logoLink: {
		textDecoration: 'none',
		marginLeft: theme.spacing(2),
	},
	logoText: {
		display: 'flex',
		alignItems: 'center',
	},
	logo: {
		height: '25px',
		marginLeft: '-1px',
	},
	container: {
		display: 'flex',
		alignItems: 'center',
		flex: 1,
	},
}));

export const GoogleStyles = makeStyles((theme) => ({
	login: {
		width: '120px',
		display: 'flex',
		justifyContent: 'center',
	},
	logout: {
		margin: theme.spacing(3),
		padding: theme.spacing(3),
		flexGrow: 1,
	},
}));

export const DrawerStyles = makeStyles((theme) => ({
	nav: {
		width: navWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		zIndex: theme.zIndex.appBar - 1,
	},
	navIcon: {
		marginLeft: theme.spacing(1),
		minWidth: '35px !important',
	},
	navOpen: {
		marginTop: '64px',
		backgroundColor: '#3F4443 !important',
		width: navWidth,
	},
	navClose: {
		marginTop: '64px',
		backgroundColor: '#3F4443 !important',

		overflowX: 'hidden',
		width: navClosedWidth,
	},
	navText: {
		textDecoration: 'none',
		color: 'white !important',
	},
	link: {
		height: '48px',
	},
}));
