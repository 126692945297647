import * as yup from 'yup';
import { OptionMap, StaticField } from "../types";

const settingOptionsValidation = (options: StaticField[], optionsMap: OptionMap) => {
	const validation: {[key: string]: any} = {};
	options.forEach((option) => {

		const match = optionsMap[option.key];
		if (match) {
			if (match.inputType === 'PhoneField') {
				validation[option.key] = yup.number().integer()
			} else if (match.inputType === 'TextField') {
				validation[option.key] = yup.string().min(2).max(256)
			} else if (match.inputType === 'Checkbox') {
				validation[option.key] = yup.boolean()
			} else {
				validation[option.key] = yup.string()
			}
		}

	})
	return yup.object().shape(validation);
}

export default settingOptionsValidation;