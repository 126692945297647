import React, { useState } from 'react';
import { Box, Card, CardContent, Typography, TextField, Button } from '@mui/material';
import Submission from './Submission';

const GatherUpCard = ({ credData, providers, updateRender }: any): React.ReactElement => {
	const provider = providers.filter((p: any) => p.name === 'GatherUp');
	const filteredData = credData.filter((cd: any) => cd.providerId === provider[0].id);

	let initData = {
		api_key: '',
		clientId: '',
		agent: '',
		businessId: '',
	};

	if (filteredData.length > 0) {
		initData = { ...initData, ...filteredData[0].credentialData };
	}

	const [api_key, setApiKey] = useState<string>(initData.api_key);
	const [clientId, setClientId] = useState<string>(initData.clientId);
	const [agent, setAgent] = useState<string>(initData.agent);
	const [businessId, setBusinessId] = useState<string>(initData.businessId);

	const [submitting, setSubmitting] = useState<Boolean>(false);

	const resetComponent = () => {
		updateRender();
		setSubmitting(false);
	};

	const pendingChanges = () => {
		let pending = false;
		Object.entries(initData).forEach(([key, value]) => {
			// eslint-disable-next-line
			if (!pending && value !== eval(key)) {
				pending = true;
			}
		});

		return pending;
	};

	return (
		<>
			{submitting ? (
				<Submission
					resetComponent={resetComponent}
					providerName={'Yext'}
					providerId={provider[0].id}
					credData={{ api_key: api_key, clientId: clientId, agent: agent, businessId: businessId }}
				/>
			) : null}
			<Card
				sx={{
					width: '94%',
					minHeight: '370px',
					position: 'relative',
					display: 'flex',
					background: '#3F4443',
					color: '#93DA49',
					margin: '20px',
					height: '25vh',
					flexDirection: 'column',
				}}
			>
				<Typography
					variant="h4"
					sx={{
						display: 'flex',
						justifyContent: 'flex-start',
						alignItems: 'center',
						background: '#303030',
						padding: '15px 15px',
					}}
				>
					GatherUp
				</Typography>
				<CardContent
					sx={{
						height: '100%',
						padding: '10px 30px',
						display: 'flex',
					}}
				>
					<Box sx={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
						<TextField
							margin="dense"
							autoFocus
							id="apiKey"
							label="Api Key"
							type="text"
							variant="filled"
							value={api_key}
							sx={{
								input: {
									background: '#a7a7a7',
								},
							}}
							onChange={(e) => setApiKey(e.target.value)}
						/>
						<TextField
							margin="dense"
							autoFocus
							id="clientId"
							label="Client Id"
							type="text"
							variant="filled"
							value={clientId}
							sx={{
								input: {
									background: '#a7a7a7',
								},
							}}
							onChange={(e) => setClientId(e.target.value)}
						/>
						<TextField
							margin="dense"
							autoFocus
							id="agent"
							label="Agent"
							type="text"
							variant="filled"
							value={agent}
							sx={{
								input: {
									background: '#a7a7a7',
								},
							}}
							onChange={(e) => setAgent(e.target.value)}
						/>
						<TextField
							margin="dense"
							autoFocus
							id="businessId"
							label="Business Id"
							type="text"
							variant="filled"
							value={businessId}
							sx={{
								input: {
									background: '#a7a7a7',
								},
							}}
							onChange={(e) => setBusinessId(e.target.value)}
						/>
					</Box>
					<Box sx={{ display: 'flex', width: '20%', justifyContent: 'center', alignItems: 'center' }}>
						<Button
							color="secondary"
							variant="contained"
							sx={{ marginLeft: '30px' }}
							onClick={() => setSubmitting(true)}
							disabled={!pendingChanges()}
						>
							<Typography variant="subtitle1">
								{pendingChanges() ? 'Save Changes' : 'No Pending Changes'}
							</Typography>
						</Button>
					</Box>
				</CardContent>
			</Card>
		</>
	);
};

export default GatherUpCard;
