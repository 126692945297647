const serverURL = process.env.REACT_APP_API_URL;

export const login = async (
	accessToken: string,
	userData: string,
	unauthorizedCallback: () => void,
	callback?: (data: any) => void,
): Promise<void> => {
	try {
		const response = await fetch(`${serverURL}/auth`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: '*/*',
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify(userData)
		});
		if (response.status === 200) {
			response.json().then((data) => {
				callback && callback(data);
				return;
			});
		} else if (response.status === 403) {
			throw new Error('You have no access to this resource. Please contact support');
		} else {
			throw new Error(response.statusText);
		}
	} catch (error: any) {
		unauthorizedCallback();
		throw new Error(error.message || 'Error');
	}
};
