import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

const DeleteUserDialog = ({ openDelete, setOpenDelete, submitDeleteUser, deleteUserName }: any): React.ReactElement => {
	const evalClose = (reason: string) => {
		if (reason === 'backdropClick') return;
		setOpenDelete(false)
	}

	return (
		<Dialog open={openDelete} onClose={(e, reason) => evalClose(reason)}>
			<DialogTitle>Delete User</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{` Are you sure you want to delete user: ${deleteUserName}`}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => setOpenDelete(false)}>Cancel</Button>
				<Button onClick={submitDeleteUser}>Confirm</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DeleteUserDialog;
