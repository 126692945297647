//Node Modules
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	email: null,
	familyName: null,
	givenName: "Anon",
	googleId: null,
	imageUrl: null,
	name: null,
	internalId: null,
	tokenId: null,
	jwt: null,
	refreshToken: null,
};

export const userReducer = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUser: (state, action) => {
			return action.payload;
		},
		logoutUser: (state) => {
			localStorage.clear();
			return initialState;
		},
	},
});

export const { setUser, logoutUser } = userReducer.actions;

export default userReducer.reducer;
