// Node Modules
import React from 'react';

// Material
import { Box } from '@mui/material';

// Internal
import InvitationLogList from './components/InvitationLogList';

const InvitationLogs = () => {
	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
			<InvitationLogList />
		</Box>
	);
};

export default InvitationLogs;
