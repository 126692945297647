import React from 'react';
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import { KVPOption, Option, OptionalSettings, StaticField } from '../types';

const AddSettingForm = (props: { settings: KVPOption[]; setSettings: (settings: StaticField[]) => void }): React.ReactElement => {
	const { settings, setSettings } = props;
	const optionalSettings: OptionalSettings[] = [
		{ displayName: 'SMS Review Solicitation', key: 'smsSolicitation' },
		{ displayName: 'Include Testimonials', key: 'includeTestimonials' },
	];

	const options = createAddFieldOptions(optionalSettings, settings);

	const handleSubmit = (event: SelectChangeEvent): void => {
		const value: StaticField = { key: event.target.value, value: '' };
		setSettings([...settings, value]);
	};

	return (
		<FormControl sx={{ m: 1, width: 300 }}>
			<InputLabel>Add Setting</InputLabel>
			<Select
				input={<OutlinedInput label="Add Setting" />}
				style={{ width: '240px' }}
				disabled={!options.length}
				onChange={handleSubmit}
				defaultValue=""
			>
				<MenuItem key="default-value" value="">-</MenuItem>
				{options.map((option, index) => (
					<MenuItem key={`k-${index}`} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default AddSettingForm;

const createAddFieldOptions = (optionalSettings: OptionalSettings[], currentSettings: KVPOption[]): Option[] => {
	const currentKeys = currentSettings.map((setting) => setting.key);
	const allowedOptions = optionalSettings.filter((option: OptionalSettings) => !currentKeys.includes(option.key));
	return allowedOptions.map((option) => {
		return { name: option.key, label: option.displayName, value: option.key };
	});
};
