import { Typography } from "@mui/material";

const NoResults = ({home}: any) => {
	return (
		<Typography sx={{ maxWidth: '100vw', display: 'flex', flexDirection: 'column', padding: '50px 50px', alignItems: 'center' }}>
			{home ?  'No Results Found' : 'Organization has no sub organizations'}
		</Typography>
	)
};

export default NoResults;