import React from 'react';
import MultiSelectField from './MultiSelectField';

interface MultiSelectOptions {
	value: string | number;
	label: string;
}

const SourcesMultiSelect = (props: {
	formik: any;
	name: string;
	displayName: string;
	options: MultiSelectOptions[];
}): React.ReactElement => {
	const { formik, name, displayName, options } = props;

	return <MultiSelectField formik={formik} name={name} displayName={displayName} options={options} />;
};

export default SourcesMultiSelect;
