import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';

const CreateEntityDialog = ({ open, setOpen, submitEntity, setNewCrmId, setNewEntityName, newEntityName, newCrmId }: any): React.ReactElement => {
	const evalClose = (reason: string) => {
		if (reason === 'backdropClick') return;
		setOpen(false)
	}
	
	return (
		<Dialog open={open} onClose={(e, reason) => evalClose(reason)}>
			<DialogTitle>Add Org</DialogTitle>
			<DialogContent>
				{/* <DialogContentText>
					To subscribe to this website, please enter your email address here. We will send updates occasionally.
				</DialogContentText> */}
				<TextField autoFocus margin="dense" id="name" label="Name" type="text" fullWidth variant="standard" value={newEntityName} onChange={(e) => setNewEntityName(e.target.value)}/>
				<TextField autoFocus margin="dense" id="crmId" label="CRM Id (Optional)" type="text" fullWidth variant="standard" value={newCrmId} onChange={(e) => setNewCrmId(e.target.value)}/>

			</DialogContent>
			<DialogActions>
				<Button onClick={() => setOpen(false)}>Cancel</Button>
				<Button onClick={submitEntity}>Create</Button>
			</DialogActions>
		</Dialog>
	);
};

export default CreateEntityDialog;
