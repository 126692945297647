import React, { ReactChild, ReactChildren, useEffect, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';
import { createUserValidation } from './validators';

export const ValidationWrapper = (props: {
	children: ReactChild | ReactChildren;
	userData: { name: string; email: string };
}): React.ReactElement => {
	const { userData } = props;
	const [validated, setValidated] = useState<boolean | null>(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	useEffect(() => {
		createUserValidation({ name: userData.name, email: userData.email }).then(([valid, errorMessage]: [boolean, string]) => {
			setValidated(valid);
			if (errorMessage) {
				setErrorMessage(errorMessage);
			}
		});
	});

	return validated ? (
		<>{props.children}</>
	) : (
		<Snackbar open={true} autoHideDuration={500}>
			<Alert variant="filled" severity="error" sx={{ width: '100%' }}>
				{`Invalid user data: ${errorMessage}`}
			</Alert>
		</Snackbar>
	);
};
