import { Snackbar, Alert } from '@mui/material';
import React from 'react';
import { usePost } from '../../../utils/useFetch';

const NewPermissionSubmission = ({resetComponent, user, entityId, permission} : any): React.ReactElement => {
	const settingsUpdateRequest = usePost('/entities/createPermission', true, null, {userId: user, entityId: entityId, permission: permission});

	return !settingsUpdateRequest.loading ? (
		<Snackbar
			open={true}
			autoHideDuration={1000}
			onClose={resetComponent}
			message={`Created Successfully`}
		>
			<Alert onClose={resetComponent} severity="success" sx={{ width: '100%' }}>
				This is a success message!
			</Alert>
		</Snackbar>
	) : (
		<></>
	);
};

export default NewPermissionSubmission;
