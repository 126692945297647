import React from 'react';
import { TableBody } from '@mui/material';
import { IReview } from '../../../services/api/reviews';
import { FeedbackReview } from './FeedbackReview';

export const FeedbackReviewTableBody = (props: { reviews: IReview[] }) => {
	const { reviews } = props;
	return (
		<TableBody>
			{reviews.map((review: IReview, index: number) => {
				return <FeedbackReview key={`review-${index}`} review={review} index={index + 1} />;
			})}
		</TableBody>
	);
};
