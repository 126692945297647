import * as yup from 'yup';

const reviewFilterValidationSchema = yup.object().shape({
	minRating: yup.number().min(0).max(5).integer(),
	hasContent: yup.boolean(),
	hasTitle: yup.boolean(),
	hasName: yup.boolean(),
	hasEmail: yup.boolean(),
	allowedSources: yup.array().of(yup.string()),
	allowedStatus: yup.array().of(yup.string()),
});

export default reviewFilterValidationSchema;
