import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { usePost } from '../../utils/useFetch';
import { Loading } from '../Global/components/Loading';
import YextCard from './components/YextCard';
import GatherUpCard from './components/GatherUp';

const EntityCredentials = (): React.ReactElement => {
	const entity = useSelector((state: any) => state.entity);

	const [reRender, setReRender] = useState<Boolean>(false);

	const updateRender = () => {
		setReRender(!reRender);
	}

	const settingsRequest = usePost(`/entities/credentials`, true, [reRender], { entityId: entity.id });

	return !settingsRequest.loading ? (
		settingsRequest.success ? (
			<Box sx={{ display: 'flex', flexDirection: 'column', flex: '1', alignItems: 'center', position: 'relative' }}>
				<Box
					sx={{
						height: '100px',
						display: 'flex',
						width: '100%',
						justifyContent: 'flex-start',
						alignItems: 'center',
						padding: '0 50px',
					}}
				>
					<Typography variant="h4">Credentials</Typography>
				</Box>
				<YextCard updateRender={updateRender} credData={settingsRequest.data.creds} providers={settingsRequest.data.providers}/>
				<GatherUpCard updateRender={updateRender} credData={settingsRequest.data.creds} providers={settingsRequest.data.providers}/>
			</Box>
		) : (
			<>API Data Population Failure</>
		)
	) : (
		<Loading />
	);
};

export default EntityCredentials;
