import * as yup from 'yup';

let schema = yup.object().shape({
	name: yup.string().required().min(4),
	email: yup.string().email().required(),
});

export const createUserValidation = async (userData: { name: string; email: string }): Promise<[boolean, string]> => {
	let valid: boolean;
	let errorMessage: string = '';
	try {
		await schema.validate(userData);
		valid = true;
	} catch (error: any) {
		valid = false;
		if (error.name === 'ValidationError') {
			errorMessage = error.errors[0];
		}
	}
	return [valid, errorMessage];
};
