import { Box, TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

const SearchBar = ({home, setsearchCriteria}: any) => {
	const entity = useSelector((state: any) => state.entity);

	return (
		<Box sx={{height: '100px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 50px'}}>
			{home ? 
				<Typography variant="h4">{'Organizations'}</Typography> : 
				<Typography variant="h5">{`${entity.name} Sub Organizations`}</Typography>
			}
			<TextField
				id="outlined-basic"
				variant="outlined"
				fullWidth
				label="Search"
				sx={{width: '300px'}}
				onChange={e => setsearchCriteria(e.target.value)}
			/>
		</Box>
	);
};

export default SearchBar;