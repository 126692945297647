import React from 'react';
import MUIPagination from '@mui/material/Pagination';
import PageSelect from './PageSelect';
import { Box } from '@mui/material';

interface PaginationProps {
	setCurrentPage: (page: number) => void;
	setRowsPerPage: (num: number) => void;
	setTotalPages: (num: number) => void;
	currentPage: number;
	rowsPerPage: number;
	totalPages: number;
}

export const Pagination = (props: PaginationProps): React.ReactElement => {
	const { rowsPerPage, currentPage, totalPages, setRowsPerPage, setCurrentPage, setTotalPages } = props;

	const handleChange = (event: React.ChangeEvent<unknown>, page: number): void => {
		setTotalPages(totalPages);
		setCurrentPage(page - 1);
	};

	const handleChangeRowsPerPage = (num: number): void => {
		setTotalPages(totalPages);
		setRowsPerPage(num);
		setCurrentPage(0);
	};

	return (
		<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80px', boxShadow: '0px -2px 2px -2px #808080' }}>
			<PageSelect onChange={handleChangeRowsPerPage} value={rowsPerPage} />
			<MUIPagination count={totalPages} color={'primary'} page={currentPage + 1} onChange={handleChange} />
		</Box>
	);
};
