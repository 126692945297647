//Node Modules
import { Link } from 'react-router-dom';

//Material UI
import { AppBar as MuiAppBar, IconButton, Toolbar, Typography, Box } from '@mui/material';

//Internal
import { AppBarStyles } from '../../../assets/styles';
import logo from '../../../assets/images/automatit_a.svg';
import { UserAvatar } from './UserAvatar';
import { useAuth0 } from '@auth0/auth0-react';
import { LoginButton } from './LoginButton';

export const AppBar = () => {
	const classes = AppBarStyles();
	const { isAuthenticated } = useAuth0();

	return (
		<MuiAppBar position="fixed" className={classes.appBar}>
			<Toolbar>
				<Box className={classes.container}>
					<IconButton edge="start" color="inherit" aria-label="expand nav">
						<img src={logo} alt="Logo" className={classes.logo} />
					</IconButton>

					<Link to="/" className={classes.logoLink}>
						<Typography className={classes.logoText} variant="h6" noWrap color="secondary">
							Review Service
						</Typography>
					</Link>
				</Box>
				{isAuthenticated ? <UserAvatar /> : <LoginButton />}
			</Toolbar>
		</MuiAppBar>
	);
};
