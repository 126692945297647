import { TableHead, TableRow } from '@mui/material';
import TableCell from '@mui/material/TableCell';

export const FeedbackReviewTableHead = () => {
	return (
		<TableHead sx={{ position: 'sticky', top: '0', background: '#f7f7f7', boxShadow: '0 2px 2px -2px #808080' }}>
			<TableRow></TableRow>
			<TableRow>
				<TableCell>#</TableCell>
				<TableCell>Author Name</TableCell>
				<TableCell>Rating</TableCell>
				<TableCell>Email</TableCell>
				<TableCell>Source</TableCell>
				<TableCell>Review Date</TableCell>
				<TableCell>Content</TableCell>
			</TableRow>
		</TableHead>
	);
};
