import React, { useEffect, useState } from 'react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';

interface PageSelectProps {
	onChange: (value: number) => void;
	value: number;
}

const PageSelect = (props: PageSelectProps): React.ReactElement => {
	const [value, setValue] = useState<string>(props.value.toString());

	const handleChange = (event: SelectChangeEvent): void => {
		setValue(event.target.value);
	};

	useEffect(() => {
		props.onChange(Number.parseInt(value));
		// eslint-disable-next-line
	}, [value]);

	return (
		<Select onChange={handleChange} value={value}>
			<MenuItem value={20}>20</MenuItem>
			<MenuItem value={50}>50</MenuItem>
			<MenuItem value={100}>100</MenuItem>
		</Select>
	);
};

export default PageSelect;
