import { Snackbar } from '@mui/material';
import React from 'react';
import { usePost } from '../../../utils/useFetch';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { FormSubmissionType } from '../types';

const FormSubmission = (props: FormSubmissionType): React.ReactElement => {
	const { settings, updatedSettings, entity, setSubmitting } = props;

	const optionsPost = [];

	for (let refKey in updatedSettings) {
		if (updatedSettings.hasOwnProperty(refKey)) {
			let setting = settings.find((s) => s.key === refKey);

			if (setting) {
				optionsPost.push({ id: setting.id, key: refKey, value: updatedSettings[refKey] });
			} else {
				optionsPost.push({ key: refKey, value: updatedSettings[refKey] });
			}
		}
	}

	const settingsUpdateRequest = usePost('/options', true, null, { options: optionsPost, entityId: entity.id });

	return !settingsUpdateRequest.loading ? (
		<Snackbar
			open={true}
			autoHideDuration={2000}
			onClose={() => setSubmitting(false)}
			message={`${entity.name} Updated Successfully`}
			action={
				<IconButton size="small" aria-label="close" color="inherit" onClick={() => setSubmitting(false)}>
					<CloseIcon fontSize="small" />
				</IconButton>
			}
		/>
	) : (
		<></>
	);
};

export default FormSubmission;
