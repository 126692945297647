import React from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useFetch, usePost } from '../../utils/useFetch';
import { Loading } from '../Global/components/Loading';
import Settings from './components/Settings';

const EntitySettings = (): React.ReactElement => {
	const entity = useSelector((state: any) => state.entity);
	const settingsRequest = usePost(`/entities/settings`, true, null, { entityId: entity.id });
	const optionsMapRequest = useFetch('/options/map', true);

	const requestsLoaded: boolean = !settingsRequest.loading && !optionsMapRequest.loading;
	const requestsSuccess: boolean = settingsRequest.success && optionsMapRequest.success;

	return requestsLoaded ? (
		requestsSuccess ? (
			<Box sx={{ display: 'flex', flexDirection: 'column', flex: '1', alignItems: 'center', position: 'relative' }}>
				<Box
					sx={{
						height: '100px',
						display: 'flex',
						width: '100%',
						justifyContent: 'flex-start',
						alignItems: 'center',
						padding: '0 50px',
					}}
				>
					<Typography variant="h4">Settings</Typography>
				</Box>
				<Settings optionsMap={optionsMapRequest.data.data} settings={settingsRequest.data.data} entity={entity} />
			</Box>
		) : (
			<>API Data Population Failure</>
		)
	) : (
		<Loading />
	);
};

export default EntitySettings;
