import React, { ChangeEvent, useEffect, useState } from 'react';
import { TextField as MuiTextField, FormControl } from '@mui/material';

const TextField = (props: { name: string; label: string; formik: any }): React.ReactElement => {
	const { formik, name, label } = props;
	const [value, setValue] = useState<string>('');

	const handleChange = (v: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setValue(v.target.value);
	};

	useEffect(() => {
		formik.values[name] = value;
	}, [value, formik.values, name]);

	return (
		<div>
			<FormControl sx={{ m: 1, width: 300 }}>
				<MuiTextField name={name} label={label} value={value} onChange={handleChange} />
			</FormControl>
		</div>
	);
};

export default TextField;
