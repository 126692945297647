import { Snackbar, Alert } from '@mui/material';
import { usePost } from '../../../utils/useFetch';
import { useSelector } from 'react-redux';

const Submission = ({ resetComponent, providerId, providerName, credData }: any) => {
	const entity = useSelector((state: any) => state.entity);

	const settingsUpdateRequest = usePost('/entities/updateCredentials', true, null, {
		credData: credData,
		providerId: providerId,
		entityId: entity.id,
	});

	return !settingsUpdateRequest.loading ? (
		settingsUpdateRequest.success ? (
			<Snackbar open={true} autoHideDuration={1000} onClose={resetComponent}>
				<Alert onClose={resetComponent} severity="success" sx={{ width: '100%' }}>
					Updated {providerName} Credentials Successfully
				</Alert>
			</Snackbar>
		) : (
			<Snackbar open={true} autoHideDuration={1000} onClose={resetComponent}>
				<Alert onClose={resetComponent} severity="error" sx={{ width: '100%' }}>
					Update Failed
				</Alert>
			</Snackbar>
		)
	) : (
		<></>
	);
};

export default Submission;
