import React, {useState} from "react";
import {Checkbox, FormControlLabel, FormGroup, TextField} from "@mui/material";
import {SettingFieldProps} from "../types";

export const SettingTextField = (props: SettingFieldProps): React.ReactElement => {
	const {option, edit, formik} = props;

	return (
		<TextField
			sx={{marginBottom: '20px'}}
			disabled={!edit}
			key={`field-key-${option.key}`}
			label={option.displayName || option.key}
			value={formik.values[option.key] ?? ''}
			onChange={formik.handleChange(option.key)}
			error={formik.touched[option.key] && Boolean(formik.errors[option.key])}
			helperText={formik.touched[option.key] && formik.errors[option.key]}
		></TextField>
	);
};

export const SettingCheckbox = (props: SettingFieldProps): React.ReactElement => {
	const {option, edit, formik} = props;

	const [checked, setChecked] = useState<boolean>(formik.values[option.key] === BOOL_STRINGS.TRUE)

	const handleCheckBox = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const value: boolean = event.target.checked;
		const transformedToString: string = transformBooleanToString(value);
		setChecked(value);
		formik.values[option.key] = transformedToString;
	}

	return (
		<FormGroup>
			<FormControlLabel control={
				<Checkbox
				disabled={!edit}
				checked={checked}
				onChange={handleCheckBox}
				/>
			} label={option.displayName || option.key}/>

		</FormGroup>
	);
};

export const BOOL_STRINGS = {
	TRUE: '1',
	FALSE: '0'
}

const transformBooleanToString = (val: boolean | undefined): string => {
	let transformed = BOOL_STRINGS.FALSE;
	if (val) {
		transformed = BOOL_STRINGS.TRUE;
	}
	return transformed;
}