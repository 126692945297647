import { Box } from '@mui/material';
import UserBar from './UserBar';
import { useSelector } from 'react-redux';

const generateUserBars = (entities: any, searchCriteria: string, deleteUser: Function, user: any) => {
	let filteredEntities = [];

	searchCriteria = searchCriteria.toLowerCase();

	if (searchCriteria) {
		filteredEntities = entities.filter((entity: any) => {
			return ((entity.name).toLowerCase()).includes(searchCriteria);
		});
	}

	if (!searchCriteria && filteredEntities.length === 0) {
		filteredEntities = entities;
	}

	return filteredEntities.map((entity: any) => {
		if (entity.id === user.internalId) {
			return null;
		}
		return <UserBar key={entity.id} entity={entity} deleteUser={deleteUser} />;
	});
};

const UserList = ({ entities, searchCriteria, deleteUser }: any) => {
	//const entitiesRequest = usePost(`/entities/users`, true, [entityId], { internalId: parseInt(entityId)});
	const user = useSelector((state: any) => state.user);

	return (
		<Box sx={{ maxWidth: '100vw', display: 'flex', flexDirection: 'column', padding: '0 50px' }}>
			{generateUserBars(entities, searchCriteria, deleteUser, user)}
		</Box>
	);
};

export default UserList;
