//Node Modules
import { useSelector } from 'react-redux';
import { useState } from 'react';

//Material
import { Box } from '@mui/material';

//Internal
import SearchBar from '../Global/components/SearchBar';
import EntitySummary from './components/EntitySummary';
import CreateEntityDialog from './components/CreateEntityDialog';
import CreateEntitySubmission from './components/CreateEntitySubmission';
import EntityOverviewActions from './components/EntityOverviewActions';
import SubEntityList from './components/SubEntityList';
import { debounce } from '../../utils/common/debounce';

const EntityOverview = () => {
	const user = useSelector((state: any) => state.user);
	const entity = useSelector((state: any) => state.entity);
	const [searchCriteria, setsearchCriteria] = useState<string>('');

	const [open, setOpen] = useState(false);
	const [newEntityName, setNewEntityName] = useState<string>('');
	const [newCrmId, setNewCrmId] = useState<string>('');
	const [submittingCreation, setSubmittingCreation] = useState<Boolean>(false);
	const [reRender, setReRender] = useState<Boolean>(false);

	const updateSearchCriteria = debounce(setsearchCriteria);
	const updateNewEntityName = debounce(setNewEntityName);
	const updateNewCrmId = debounce(setNewCrmId);

	const resetComponent = () => {
		setReRender(!reRender);
		setSubmittingCreation(false);
		setOpen(false);
	};

	const submitEntity = () => {
		setOpen(false);
		setTimeout(() => setSubmittingCreation(true), 1000);
	};

	return user.jwt ? (
		<Box sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
			<Box sx={{ padding: '0 30px' }}>
				<EntitySummary entity={entity} />
			</Box>
			<SearchBar home={false} setsearchCriteria={updateSearchCriteria} />
			<SubEntityList home={false} entityId={entity.id} searchCriteria={searchCriteria} reRender={reRender}/>
			<CreateEntityDialog
				open={open}
				setOpen={setOpen}
				setNewCrmId={updateNewCrmId}
				setNewEntityName={updateNewEntityName}
				submitEntity={submitEntity}
			/>
			<EntityOverviewActions createEntity={() => setOpen(true)} />
			{submittingCreation ? (
				<CreateEntitySubmission resetComponent={resetComponent} name={newEntityName} crmId={newCrmId} />
			) : null}
		</Box>
	) : (
		<>Not Signed In</>
	);
};

export default EntityOverview;
