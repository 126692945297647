import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { Fab } from '@mui/material';

const FormActions = (props: { edit: boolean; setEdit: (arg: boolean) => void }): React.ReactElement => {
	const { edit, setEdit } = props;
	return (
		<>
			<Fab
				onClick={() => setEdit(!edit)}
				sx={{ position: 'absolute', bottom: '0', right: '0' }}
				color="primary"
				aria-label="edit"
			>
				{edit ? <CancelIcon /> : <EditIcon />}
			</Fab>
			{edit ? (
				<>
					<Fab
						sx={{ position: 'absolute', bottom: '0', right: '65px' }}
						type="submit"
						color="secondary"
						aria-label="save"
					>
						<SaveIcon />
					</Fab>
				</>
			) : null}
		</>
	);
};

export default FormActions;
