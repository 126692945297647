//Node Modules
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	value: true,
};

export const drawerReducer = createSlice({
	name: 'drawer',
	initialState,
	reducers: {
		toggleDrawer: (state, action) => {
			state.value = action.payload;
		},
	},
});

export const { toggleDrawer } = drawerReducer.actions;

export default drawerReducer.reducer;
