import { useFormik } from 'formik';
import React, { useState } from 'react';
import {
	KVPOption,
	OptionMap,
	SettingEntity,
	SettingChooseFieldProps,
	SettingFormikValue,
	StaticField,
	SettingOption
} from '../types';
import { addStaticFields } from './utils';
import Field from './Field';
import FormActions from './FormActions';
import FormSubmission from './FormSubmission';
import { AddNewSetting } from './AddNewSetting';
import {SettingCheckbox, SettingTextField} from "./Fields";
import settingOptionsValidation from "./settingOptionsValidation";

const chooseField = (props: SettingChooseFieldProps): React.ReactElement => {
	const {option, optionsMap, edit, formik, index} = props;
	const definition = optionsMap[option.key];
	if (definition) {
		const filledOption: SettingOption = {
			key: option.key,
			value: option.value,
			id: option.id,
			displayName: definition.displayName,
		}
		if (definition.inputType === 'Checkbox') {
			return <SettingCheckbox edit={edit} option={filledOption} key={`field-${index}`} formik={formik} />
		}else {
			return <SettingTextField edit={edit} option={filledOption} key={`field-${index}`} formik={formik} />
		}
	} else {
		return <Field edit={edit} option={option} key={`field-${index}`} formik={formik} />
	}
}

const generateSettingsBars = (data: KVPOption[], optionsMap: OptionMap | undefined, edit: boolean, formik: any): React.ReactElement[] => {
	const processedFields: StaticField[] = addStaticFields(data);
	return processedFields.map((option: StaticField, index) => {
		return chooseField({option, optionsMap: optionsMap ? optionsMap : {}, edit, formik, index})
	});
};

const generateInitialValues = (settings: KVPOption[]): SettingFormikValue => {
	const initialValues: SettingFormikValue = {};
	settings.forEach(({ key, value }) => {
		initialValues[key] = value;
	});

	return initialValues;
};

const Settings = (props: { settings: KVPOption[]; entity: SettingEntity, optionsMap?: OptionMap }): React.ReactElement => {
	const { settings, entity, optionsMap } = props;
	const [currentSettings, setCurrentSettings] = useState<KVPOption[]>(settings);
	const [edit, setEdit] = useState<boolean>(false);
	const [submitting, setSubmitting] = useState<boolean>(false);

	const formik = useFormik<SettingFormikValue>({
		initialValues: generateInitialValues(currentSettings),
		validationSchema: settingOptionsValidation(settings, optionsMap || {}),
		onSubmit: () => {
			setSubmitting(true);
		},
	});

	return (
		<>
			{submitting ? (
				<FormSubmission
					settings={currentSettings}
					updatedSettings={formik.values}
					entity={entity}
					setSubmitting={setSubmitting}
				/>
			) : null}
			<form
				onSubmit={formik.handleSubmit}
				style={{
					display: 'flex',
					flexDirection: 'column',
					width: '95%',
					position: 'relative',
					paddingBottom: '50px',
				}}
			>
				{generateSettingsBars(currentSettings, optionsMap, edit, formik)}
				<FormActions edit={edit} setEdit={setEdit} />
			</form>
			<AddNewSetting edit={edit} settings={currentSettings} setSettings={setCurrentSettings} />
		</>
	);
};

export default Settings;
