import axios, { AxiosRequestHeaders, Method } from 'axios';
import { to } from 'await-to-js';

interface IRequest {
	url: string;
	data?: object;
	config?: {
		method: Method;
		contentType?: string; // interpreted to 'Content-Type' in code
		param?: string;
	};
}

interface IOptions {
	headers?: AxiosRequestHeaders;
}

export const client = async (request: IRequest) => {
	const { url, data, config = { method: 'get' } } = request;

	let contentType = config.contentType === '' ? config.contentType : 'application/json';

	let headers: HeadersInit = {
		'Content-Type': contentType,
	};

	const options: IOptions = {
		headers,
	};
	// TODO: Set Auth headers.

	let err: any;
	let response: any;

	[err, response] = await to(
		axios({
			method: config.method,
			url: `${process.env.REACT_APP_API_URL}${url}`,
			data: data ? data : null,
			headers: options.headers,
		}),
	);

	if (!response && err) {
		throw Object(err.response || { data: { message: 'Could not complete request. Check your internet connection.' } });
	} else if (response.status >= 200 && response.status <= 299) {
		return response.data;
	} else if (response) {
		return response;
	}
};
