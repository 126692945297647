import { Box, Card, CardContent, Typography } from '@mui/material';
import moment from 'moment';
import { usePost } from '../../../utils/useFetch';
import { SummaryLoading } from './SummaryLoading';

const EntitySummary = ({ entity }: any) => {
	const entitiesRequest = usePost(`/entities/summary`, true, [entity], { entityId: entity.id });

	return (
		<Card
			sx={{
				width: '100%',
				minHeight: '75px',
				margin: '5px',
				position: 'relative',
				display: 'flex',
				background: '#3F4443',
				color: '#93DA49',
				marginTop: '30px',
				height: '25vh',
				flexDirection: 'column',
			}}
		>
			<Typography
				variant="h4"
				sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#303030', padding: '15px 0' }}
			>
				{entity.name}
			</Typography>
			<CardContent sx={{ height: '100%', padding: '15px 30px', display: 'flex', justifyContent: 'space-around' }}>
				{!entitiesRequest.loading ? (
					entitiesRequest.success ? (
						<>
							{/* <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around',  width: '45%'}}>
								<Typography variant="body1">Pending Feedbacks:</Typography>
								<Typography variant="body1">Invitations Sent in Last 30 Days:</Typography>
								<Typography variant="body1">Invitations Completed in Last 30 Days:</Typography>
							</Box> */}
							<Box
								sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', width: '27.5%' }}
							>
								<Typography variant="body1">Total Reviews: {entitiesRequest.data.reviewCount}</Typography>
								<Typography variant="body1">
									Last Review Date:{' '}
									{entitiesRequest.data.lastReview
										? moment(entitiesRequest.data.lastReview).format('L h:mm A')
										: 'N/A'}
								</Typography>
								<Typography variant="body1">
									Avg Review Rating: {entitiesRequest.data.avgRating.toFixed(2)}{' '}
								</Typography>
							</Box>
							<Box
								sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', width: '27.5%' }}
							>
								<Typography variant="body1">
									Created On: {moment(entitiesRequest.data.createdTs).format('L h:mm A')}
								</Typography>
								<Typography variant="body1">
									Updated On: {moment(entitiesRequest.data.modifiedTs).format('L h:mm A')}
								</Typography>
								<Typography variant="body1">Updated By: {entitiesRequest.data.updatedByName}</Typography>
							</Box>
						</>
					) : (
						<>API Data Population Failure</>
					)
				) : (
					<SummaryLoading info={true} />
				)}
			</CardContent>
		</Card>
	);
};

export default EntitySummary;
