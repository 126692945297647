import React from 'react';
import { FormGroup } from '@mui/material';
import SelectorField from '../../../../../filters/Fields/SelectorField';
import TextField from '../../../../../filters/Fields/TextField';
import DateField from '../../../../../filters/Fields/DateField';

const statusOptions = [
	{ label: 'All', value: '0' },
	{ label: 'Received', value: '1' },
	{ label: 'Rejected', value: '2' },
];

const InvitationLogsFilter = (props: { formik: any }): React.ReactElement => {
	const { formik } = props;

	return (
		<FormGroup style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
			<SelectorField
				name={'status'}
				displayName={'Status'}
				options={statusOptions}
				formik={formik}
				style={{ width: '70px' }}
			/>
			<TextField name={'message'} label={'Message'} formik={formik} />
			<DateField name="createdTs" label="Created Date" formik={formik} />
		</FormGroup>
	);
};

export default InvitationLogsFilter;
