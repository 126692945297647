//Node Modules
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	id: null,
	name: null
};

export const entityReducer = createSlice({
	name: 'entity',
	initialState,
	reducers: {
		setEntity: (state, action) => {
			return action.payload;
		},
	},
});

export const { setEntity } = entityReducer.actions;

export default entityReducer.reducer;
