import React from 'react';
import { useFormik } from 'formik';
import { Button, FormGroup } from '@mui/material';
import { ObjectSchema } from 'yup';

interface CustomFiltersProps<T> {
	onChange: any;
	initFilters: T;
	validationSchema: ObjectSchema<any, any, any, any>;
	CustomFilters: (formik: any) => React.ReactElement;
}
function Filter<T>(props: CustomFiltersProps<T>): React.ReactElement {
	const { onChange, initFilters, validationSchema, CustomFilters } = props;

	const handleSubmit = (values: T) => {
		onChange(values);
	};

	const formik = useFormik<T>({
		initialValues: initFilters,
		validationSchema: validationSchema,
		onSubmit: (values) => {
			handleSubmit(values);
		},
	});

	return (
		<form onSubmit={formik.handleSubmit}>
			<FormGroup style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
				<CustomFilters formik={formik} />
				<FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
					{formik.errors ? (
						<div>
							{Object.values(formik.errors).map((error) => (
								<div>{error as unknown as React.ReactNode}</div>
							))}
						</div>
					) : null}
					<Button variant="contained" color="primary" type="submit">
						Submit
					</Button>
				</FormGroup>
			</FormGroup>
		</form>
	);
}

export default Filter;
