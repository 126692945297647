//Node Modules
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

//Material
import DashboardIcon from '@mui/icons-material/Dashboard';
import SummarizeIcon from '@mui/icons-material/Summarize';
import FeedbackIcon from '@mui/icons-material/Feedback';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import TableRowsIcon from '@mui/icons-material/TableRows';
import KeyIcon from '@mui/icons-material/Key';
import { Drawer as MuiDrawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

//Internal
import { DrawerStyles } from '../../../assets/styles';

const routeIcons = (routeName: string) => {
	switch (routeName) {
		case 'Org Settings':
			return <DashboardIcon color="secondary" />;
		case 'View Reviews':
			return <SummarizeIcon color="secondary" />;
		case 'Invitation Log':
			return <TableRowsIcon color="secondary" />;
		case 'Manage Feedback':
			return <FeedbackIcon color="secondary" />;
		case 'Manage Users':
		case 'Manage Org Users':
			return <ManageAccountsIcon color="secondary" />;
		case 'Settings':
			return <SettingsIcon color="secondary" />;
		case 'Service Credentials':
			return <KeyIcon color="secondary" />;
		default:
			return <DashboardIcon color="secondary" />;
	}
};

export const Drawer = () => {
	const classes = DrawerStyles();
	const open = useSelector((state: any) => state.drawer.value);
	const user = useSelector((state: any) => state.user);
	const entity = useSelector((state: any) => state.entity);

	const auth = user?.jwt;

	const userRoutes = [
		{
			name: 'Manage Users',
			route: '/manageUsers',
		},
	];

	const entityRoutes = [
		// {
		// 	name: 'Manage Org Users',
		// 	route: '/entity/manageUsers',
		// },
		{
			name: 'View Reviews',
			route: '/entity/reviews',
			reqAdmin: false,
		},
		{
			name: 'Invitation Log',
			route: '/entity/invitationLog',
			reqAdmin: false,
		},
		{
			name: 'Manage Feedback',
			route: '/entity/manageFeedback',
			reqAdmin: false,
		},
		{
			name: 'Service Credentials',
			route: '/entity/credentials',
			reqAdmin: true,
		},
		{
			name: 'Settings',
			route: '/entity/settings',
			reqAdmin: true,
		},
	];

	return auth ? (
		<MuiDrawer
			variant="permanent"
			className={classNames(classes.nav, {
				[classes.navOpen]: open,
				[classes.navClose]: !open,
			})}
			classes={{
				paper: classNames({
					[classes.navOpen]: open,
					[classes.navClose]: !open,
				}),
			}}
			open={open}
		>
			<List sx={{ paddingTop: '0px' , paddingBottom: (!user.isAdmin ? '0px' : '8px')}}>
				<Link className={classes.navText} key={user.name} to={'/'}>
					<ListItem button key={user.name} className={classes.link} sx={{ background: '#303030', color: '#93DA49' }}>
						{open && <ListItemText primary={'Organizations'} sx={{ marginLeft: '10px' }} />}
					</ListItem>
				</Link>
				{user.isAdmin
					? userRoutes.map((routes) => (
							<Link className={classes.navText} key={routes.name} to={routes.route}>
								<ListItem button key={routes.name} className={classes.link} sx={{ marginLeft: '20px' }}>
									<ListItemIcon className={classes.navIcon}>{routeIcons(routes.name)}</ListItemIcon>
									{open && <ListItemText primary={routes.name} />}
								</ListItem>
							</Link>
					  ))
					: null}
			</List>
			{entity.id ? (
				<List sx={{ paddingTop: '0px' }}>
					<Link className={classes.navText} key={entity.name} to={`/entity/overview/${entity.id}`}>
						<ListItem
							button
							key={entity.name}
							className={classes.link}
							sx={{ background: '#303030', color: '#93DA49' }}
						>
							{open && <ListItemText primary={entity.name} sx={{ marginLeft: '10px' }} />}
						</ListItem>
					</Link>
					{entityRoutes.map((route) => {
						if (route.reqAdmin === false || (route.reqAdmin === true && user.isAdmin === true)) {
							return (
								<Link className={classes.navText} key={route.name} to={route.route}>
									<ListItem button key={route.name} className={classes.link} sx={{ marginLeft: '20px' }}>
										<ListItemIcon className={classes.navIcon}>{routeIcons(route.name)}</ListItemIcon>
										{open && <ListItemText primary={route.name} />}
									</ListItem>
								</Link>
							);
						} else return null;
					})}
				</List>
			) : null}
		</MuiDrawer>
	) : null;
};
