import React from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';

const InvitationLogHeader = (): React.ReactElement => {
	return (
		<TableHead sx={{position: 'sticky', top: '0', background: '#f7f7f7', boxShadow: '0 2px 2px -2px #808080'}}>
			<TableRow>
				<TableCell>#</TableCell>
				<TableCell>Message</TableCell>
				<TableCell>Details</TableCell>
				<TableCell>Status</TableCell>
				<TableCell>Provider</TableCell>
				<TableCell>Date</TableCell>
			</TableRow>
		</TableHead>
	);
};

export default InvitationLogHeader;
