import { Box } from '@mui/material';
import EntityBar from '../../Global/components/EntityBar';
import { usePost } from '../../../utils/useFetch';
import { Loading } from '../../Global/components/Loading';
import NoResults from '../../Global/components/NoResults';

const generateEntityBars = (entities: any, searchCriteria: string) => {
	let filteredEntities = [];

	searchCriteria = searchCriteria.toLowerCase();

	if (searchCriteria) {
		filteredEntities = entities.filter((entity: any) => {
			return entity.name.toLowerCase().includes(searchCriteria);
		});
	} else {
		filteredEntities = entities.filter((entity: any) => {
			return entity.ChildEntities.length !== 0 || entity.ParentEntities.length === 0;
		});
	}

	if (!searchCriteria && filteredEntities.length === 0) {
		filteredEntities = entities;
	}

	const bars = filteredEntities.map((entity: any) => {
		return <EntityBar key={entity.id} entity={entity} />;
	});

	if (bars.length > 0) return bars;
	return <NoResults home={false} />;
};

const SubEntityList = ({ entityId, searchCriteria, reRender }: any) => {
	const entitiesRequest = usePost(`/entities/subEntities`, true, [entityId, reRender], { entityId: entityId });

	return !entitiesRequest.loading ? (
		entitiesRequest.success ? (
			<Box sx={{ maxWidth: '100vw', display: 'flex', flexDirection: 'column', padding: '0 50px' }}>
				{generateEntityBars(entitiesRequest.data, searchCriteria)}
			</Box>
		) : (
			<>API Data Population Failure</>
		)
	) : (
		<Loading />
	);
};

export default SubEntityList;
