import React from 'react';
import { useParams } from 'react-router-dom';
import { Table, TableBody } from '@mui/material';
import { ReviewDetailsRow } from './components/ReviewDetailsRow';
import { BackButton } from '../Global/components/BackButton';
import { ReviewDetailsTableData } from './components/types';
import { usePost } from '../../utils/useFetch';
import { Loading } from '../Global/components/Loading';


export const ReviewDetails = (): React.ReactElement => {
	const params = useParams();
	const { reviewId, entityId } = params;

	const reviewRequest = usePost(`/reviews/details`, true, [], {
		reviewId: reviewId, entityId: entityId
	});

	return !reviewRequest.loading ? (
		<div>
			<h2>Review Details #{reviewId}</h2>
			<BackButton />
			{reviewRequest.data && <ReviewDetailsTable review={reviewRequest.data} />}
		</div>
	) : <Loading />;
};

export const ReviewDetailsTable = (props: { review: ReviewDetailsTableData }): React.ReactElement => {
	const { review } = props;

	return (
		<Table>
			<TableBody>
				<ReviewDetailsRow rowName={'ID'} value={review.id.toString()} />
				<ReviewDetailsRow rowName={'Status'} value={review.status} />
				<ReviewDetailsRow rowName={'Rating'} value={review.rating} />
				<ReviewDetailsRow rowName={'Deleted'} value={review.deleted ? 'Yes' : 'No'} />
				<ReviewDetailsRow rowName={'Source'} value={review.source ? review.source.displayName : 'UNDEFINED'} />
				<ReviewDetailsRow rowName={'Provider'} value={review.provider ? review.provider.name : 'UNDEFINED'} />
				<ReviewDetailsRow rowName={'Entity'} value={review.entity ? review.entity.name : 'UNDEFINED'} />
				<ReviewDetailsRow rowName={'Entity Type'} value={review.entity ? review.entity.entityType : 'UNDEFINED'} />
				<ReviewDetailsRow rowName={'Created date'} value={review.reviewDate ? formatDate(review.reviewDate) : null} />
				<ReviewDetailsRow rowName={'Title'} value={review.title} />
				<ReviewDetailsRow rowName={'Content'} value={review.content} />
			</TableBody>
		</Table>
	);
};

const formatDate = (date: Date): string => {
	const _date = new Date(date);
	return `${_date.getDate()}-${_date.getMonth() - 1}-${_date.getFullYear()}`;
};
