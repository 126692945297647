import React, { useState } from 'react';
import { Box, Card, CardContent, Typography, TextField, Button } from '@mui/material';
import Submission from './Submission';

const YextCard = ({ credData, providers, updateRender }: any): React.ReactElement => {
	const provider = providers.filter((p: any) => p.name === 'Yext');
	const filteredData = credData.filter((cd: any) => cd.providerId === provider[0].id);

	let initData = {
		api_key: '',
		location_id: '',
		accountId: '',
	};

	if (filteredData.length > 0) {
		initData = filteredData[0].credentialData;
	}

	const [api_key, setApiKey] = useState<string>(initData.api_key);
	const [location_id, setLocationId] = useState<string>(initData.location_id);
	const [accountId, setAccountId] = useState<string>(initData.accountId);

	const [submitting, setSubmitting] = useState<Boolean>(false);

	const resetComponent = () => {
		updateRender();
		setSubmitting(false);
	};

	const pendingChanges = () => {
		let pending = false;
		Object.entries(initData).forEach(([key, value]) => {
			// eslint-disable-next-line
			if (!pending && value !== eval(key)) {
				pending = true;
			}
		});

		return pending;
	};

	return (
		<>
			{submitting ? (
				<Submission
					resetComponent={resetComponent}
					providerName={'Yext'}
					providerId={provider[0].id}
					credData={{ api_key: api_key, location_id: location_id, accountId: accountId }}
				/>
			) : null}
			<Card
				sx={{
					width: '94%',
					minHeight: '300px',
					position: 'relative',
					display: 'flex',
					background: '#3F4443',
					color: '#93DA49',
					margin: '20px',
					height: '25vh',
					flexDirection: 'column',
				}}
			>
				<Typography
					variant="h4"
					sx={{
						display: 'flex',
						justifyContent: 'flex-start',
						alignItems: 'center',
						background: '#303030',
						padding: '15px 15px',
					}}
				>
					Yext
				</Typography>
				<CardContent sx={{ height: '100%', padding: '10px 30px', display: 'flex' }}>
					<Box sx={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
						<TextField
							autoFocus
							margin="dense"
							id="apiKey"
							label="Api Key"
							type="text"
							variant="filled"
							sx={{
								input: {
									background: '#a7a7a7',
								},
							}}
							value={api_key}
							onChange={(e) => setApiKey(e.target.value)}
						/>
						<TextField
							autoFocus
							margin="dense"
							id="accountId"
							label="Account Id"
							type="text"
							variant="filled"
							value={accountId}
							sx={{
								input: {
									background: '#a7a7a7',
								},
							}}
							onChange={(e) => setAccountId(e.target.value)}
						/>
						<TextField
							autoFocus
							margin="dense"
							id="locationId"
							label="Location Id"
							type="text"
							variant="filled"
							value={location_id}
							sx={{
								input: {
									background: '#a7a7a7',
								},
							}}
							onChange={(e) => setLocationId(e.target.value)}
						/>
					</Box>
					<Box sx={{ display: 'flex', width: '20%', justifyContent: 'center', alignItems: 'center' }}>
						<Button
							color="secondary"
							variant="contained"
							sx={{ marginLeft: '30px' }}
							onClick={() => setSubmitting(true)}
							disabled={!pendingChanges()}
						>
							<Typography variant="subtitle1">
								{pendingChanges() ? 'Save Changes' : 'No Pending Changes'}
							</Typography>
						</Button>
					</Box>
				</CardContent>
			</Card>
		</>
	);
};

export default YextCard;
