//Node Modules
import { useState } from 'react';

//Material
import { Box } from '@mui/material';

//Internal
import UserSearchBar from './components/UserSearchBar';
import UserList from './components/UserList';
import ManageUserActions from './components/ManageUserActions';
import ManageUserSubmission from './components/ManageUserSubmission';
import DeleteUserSubmission from './components/DeleteUserSubmission';
import CreateUserDialog from './components/CreateUserDialog';
import DeleteUserDialog from './components/DeleteUserDialog';
import { usePost } from '../../utils/useFetch';
import { Loading } from '../Global/components/Loading';
import { debounce } from '../../utils/common/debounce';
import { ValidationWrapper } from './components/ValidationWrapper';

const ManageRootUsers = () => {
	const [searchCriteria, setsearchCriteria] = useState<string>('');
	const [submittingCreation, setSubmittingCreation] = useState<Boolean>(false);
	const [submittingDeletion, setSubmittingDeletion] = useState<Boolean>(false);
	const [reRender, setReRender] = useState<Boolean>(false);

	const [open, setOpen] = useState(false);
	const [openDelete, setOpenDelete] = useState(false);

	const [newUserName, setNewUserName] = useState<string>('');
	const [newUserEmail, setNewUserEmail] = useState<string>('');
	const [deleteUserName, setDeleteUserName] = useState<string>('');
	const [deleteUserId, setDeleteUserId] = useState<number>(0);

	const updateSearchCriteria = debounce(setsearchCriteria);
	const updateNewUserName = debounce(setNewUserName);
	const updateNewUserEmail = debounce(setNewUserEmail);

	const resetComponent = () => {
		setReRender(!reRender);
		setSubmittingCreation(false);
		setSubmittingDeletion(false);
		setOpen(false);
	};

	const submitUser = () => {
		setOpen(false);
		setTimeout(() => setSubmittingCreation(true), 1000);
	};

	const submitDeleteUser = () => {
		setOpenDelete(false);
		setSubmittingDeletion(true);
	};

	const deleteUser = (e: Event, name: string, id: number) => {
		e.preventDefault();
		e.stopPropagation();
		setDeleteUserName(name);
		setDeleteUserId(id);
		setOpenDelete(true);
	};

	const entitiesRequest = usePost(`/entities/users`, true, [reRender], {});

	return !entitiesRequest.loading ? (
		entitiesRequest.success ? (
			<>
				{submittingCreation ? (
					<ValidationWrapper userData={{ name: newUserName, email: newUserEmail }}>
						<ManageUserSubmission resetComponent={resetComponent} name={newUserName} email={newUserEmail} />
					</ValidationWrapper>
				) : null}
				{submittingDeletion ? <DeleteUserSubmission resetComponent={resetComponent} deleteUserId={deleteUserId} /> : null}
				<Box sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
					<UserSearchBar home={false} setsearchCriteria={updateSearchCriteria} />
					<UserList
						home={false}
						entities={entitiesRequest.data}
						searchCriteria={searchCriteria}
						deleteUser={deleteUser}
					/>
					<ManageUserActions createUser={() => setOpen(true)} />
				</Box>
				<CreateUserDialog
					open={open}
					setOpen={setOpen}
					setNewUserEmail={updateNewUserEmail}
					setNewUserName={updateNewUserName}
					submitUser={submitUser}
				/>
				<DeleteUserDialog
					openDelete={openDelete}
					setOpenDelete={setOpenDelete}
					submitDeleteUser={submitDeleteUser}
					deleteUserName={deleteUserName}
				/>
			</>
		) : (
			<>API Data Population Failure</>
		)
	) : (
		<Loading />
	);
};

export default ManageRootUsers;
