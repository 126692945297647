import { AppBar } from './modules/Global/components';
import { BrowserRouter as Router } from 'react-router-dom';
import { GlobalStyles } from './assets/styles';
import { AppRoutes } from './routes/routes';

//Material
import { Box } from '@mui/material';

//Internal
import { Drawer } from './modules/Global/components';
import ErrorBoundary from './modules/Global/components/ErrorBoundary';

export const App = () => {
	const classes = GlobalStyles();

	return (
		<Router>
			<ErrorBoundary>
				<AppBar />
				<Box className={classes.appContent} sx={{ overflow: 'auto', height: 'auto' }}>
					<Drawer />
					<AppRoutes />
				</Box>
			</ErrorBoundary>
		</Router>
	);
};
