import React, { useEffect } from 'react';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { FormControl, TextField } from '@mui/material';
import moment, { Moment } from 'moment';

const DateField = (props: { name: string; label: string; formik: any }): React.ReactElement => {
	const { formik, name, label } = props;
	const defaultDate: Date | null = formik.values[name] ? new Date(formik.values[name]) : null;

	const [value, setValue] = React.useState<Moment | null>(defaultDate ? moment(defaultDate) : null);

	const handleChange = (newValue: Moment | null) => {
		if (newValue) {
			setValue(newValue);
		}
	};

	useEffect(() => {
		if (value) {
			formik.values[name] = value.format('YYYY-MM-DD');
		}
	}, [value, formik.values, name]);

	return (
		<FormControl sx={{ m: 1, width: 300 }}>
			<LocalizationProvider dateAdapter={DateAdapter}>
				<DesktopDatePicker
					label={label}
					inputFormat="MM/DD/YYYY"
					value={value}
					onChange={handleChange}
					renderInput={(params) => <TextField {...params} />}
				/>
			</LocalizationProvider>
		</FormControl>
	);
};

export default DateField;
