import React from 'react';
import { FormGroup } from '@mui/material';
import SelectorField from '../../../../../filters/Fields/SelectorField';
import MultiSelectField from '../../../../../filters/Fields/MultiSelectField';
import TextField from '../../../../../filters/Fields/TextField';
import DateField from '../../../../../filters/Fields/DateField';

const ratingOptions = [
	{ label: '0', value: '0' },
	{ label: '1', value: '1' },
	{ label: '2', value: '2' },
	{ label: '3', value: '3' },
	{ label: '4', value: '4' },
	{ label: '5', value: '5' },
];

const statusOptions = ['PUBLISHED', 'UNPUBLISHED', 'STORED', 'APPROVED'];

const FeedbackListFilter = (props: { formik: any }): React.ReactElement => {
	const { formik } = props;
	return (
		<FormGroup style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
			<SelectorField
				name={'minRating'}
				displayName={'Rating'}
				options={ratingOptions}
				formik={formik}
				style={{ width: '70px' }}
			/>
			<MultiSelectField formik={formik} name={'allowedStatus'} displayName={'Statuses'} options={statusOptions} />
			<TextField name={'authorEmail'} label={'Email'} formik={formik} />
			<DateField name={'reviewDate'} label={'Created Date'} formik={formik} />
		</FormGroup>
	);
};

export default FeedbackListFilter;
