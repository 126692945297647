import { Snackbar, Alert } from '@mui/material';
import React from 'react';
import { usePost } from '../../../utils/useFetch';
import { useSelector } from 'react-redux';

const CreateRootEntitySubmission = ({resetComponent, name, crmId} : any): React.ReactElement => {
	const user = useSelector((state: any) => state.user);

	let requestPost = {
		internalId: user.internalId,
		name: name,
		crmId: crmId,
	};

	const settingsUpdateRequest = usePost('/entities/createOrg', true, null, requestPost);

	return !settingsUpdateRequest.loading ? (
		<Snackbar
			open={true}
			autoHideDuration={1000}
			onClose={resetComponent}
			message={`Created Successfully`}
		>
			<Alert onClose={resetComponent} severity="success" sx={{ width: '100%' }}>
				This is a success message!
			</Alert>
		</Snackbar>
	) : (
		<></>
	);
};

export default CreateRootEntitySubmission;
