import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';

export const LoginButton = () => {
	const { loginWithRedirect } = useAuth0();
	const { enqueueSnackbar } = useSnackbar();

	const handleClick = () => {
		loginWithRedirect()
			.then()
			.catch((error: any) => {
				enqueueSnackbar(error.message || 'Cant connect to authentication server', { variant: 'error' });
			});
	};

	return (
		<Button sx={{ color: '#93DA49' }} onClick={handleClick}>
			Log In
		</Button>
	);
};
