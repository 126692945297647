import { useState } from 'react';
import { Table, Box } from '@mui/material';
import { FeedbackReviewTableHead } from './components/FeedbackReviewTableHead';
import { Pagination } from '../Pagination';
import { usePost } from '../../utils/useFetch';
import { FeedbackReviewFilters } from './components/FeedbackReviewFilters';
import { Loading } from '../Global/components/Loading';
import { FeedbacksFilterProps } from '../../services/api/feedbacks';
import { FeedbackReviewTableBody } from './components/FeedbackReviewTableBody';
import { useSelector } from 'react-redux';

const FeedbackReviewTable = () => {
	const [filters, setFilters] = useState<FeedbacksFilterProps>({});
	const [currentPage, setCurrentPage] = useState<number>(0);
	const [totalPages, setTotalPages] = useState<number>(0);
	const [rowsPerPage, setRowsPerPage] = useState<number>(20);

	const entity = useSelector((state: any) => state.entity);

	const handleChangeFilters = (newFilters: FeedbacksFilterProps): void => {
		setFilters({ ...newFilters });
		setCurrentPage(0);
	};

	const reviewsRequest = usePost(`/feedback/list`, true, [currentPage, rowsPerPage, filters], {
		entityId: entity.id,
		paginationData: { page: currentPage, perPage: rowsPerPage },
		filters: filters,
	});

	return (
		<Box style={{ width: '100%' }}>
			<Box sx={{ background: '#d7d7d7', color: 'white'}}>
				<FeedbackReviewFilters onFilterChange={handleChangeFilters} />
			</Box>
			<Box sx={{ overflow: 'auto', height: 'calc(100vh - 216px)' }}>
				{!reviewsRequest.loading ? (
					<>
						<Table>
							<FeedbackReviewTableHead />
							<FeedbackReviewTableBody reviews={reviewsRequest.data.data} />
						</Table>
					</>
				) : (
					<Loading />
				)}
			</Box>
			<Pagination
				setTotalPages={setTotalPages}
				setRowsPerPage={setRowsPerPage}
				setCurrentPage={setCurrentPage}
				totalPages={reviewsRequest.data?.paginatorData.totalPages || totalPages}
				currentPage={reviewsRequest.data ? reviewsRequest.data.paginatorData.currentPage : currentPage}
				rowsPerPage={reviewsRequest.data ? reviewsRequest.data.paginatorData.itemsForPage : rowsPerPage}
			/>
		</Box>
	);
};

export default FeedbackReviewTable;
