import React, { Component, ErrorInfo } from 'react';
import { Navigate } from 'react-router-dom';
import { ProviderContext, withSnackbar } from 'notistack';

const initialState = {
	error: '',
	errorInfo: '',
	hasError: false,
};

// interface Merged extends ProviderContext, Component {}

class ErrorBoundary extends Component<ProviderContext> {
	state = { ...initialState };
	static getDerivedStateFromError(error: any) {
		return { hasError: true, error: error };
	}
	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		// Uncomment to debug unexpected error
		// console.log(error);
		this.setState({ errorInfo });
	}
	render() {
		if (this.state.hasError) {
			this.props.enqueueSnackbar('Unexpected Error', { variant: 'error' });
			this.setState({ ...initialState });
			return <Navigate to="/" />;
		}
		return this.props.children;
	}
}

export default withSnackbar(ErrorBoundary);
