import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';

const CreateUserDialog = ({ open, setOpen, submitUser, setNewUserEmail, setNewUserName, newUserName, newUserEmail }: any): React.ReactElement => {
	const evalClose = (reason: string) => {
		if (reason === 'backdropClick') return;
		setOpen(false)
	}

	return (
		<Dialog open={open} onClose={(e, reason) => evalClose(reason)}>
			<DialogTitle>Add User</DialogTitle>
			<DialogContent>
				{/* <DialogContentText>
					To subscribe to this website, please enter your email address here. We will send updates occasionally.
				</DialogContentText> */}
				<TextField autoFocus margin="dense" id="name" label="Name" type="email" fullWidth variant="standard" value={newUserName} onChange={(e) => setNewUserName(e.target.value)}/>
				<TextField autoFocus margin="dense" id="name" label="Email Address" type="email" fullWidth variant="standard" value={newUserEmail} onChange={(e) => setNewUserEmail(e.target.value)}/>

			</DialogContent>
			<DialogActions>
				<Button onClick={() => setOpen(false)}>Cancel</Button>
				<Button onClick={submitUser}>Create</Button>
			</DialogActions>
		</Dialog>
	);
};

export default CreateUserDialog;
