import React from 'react';
import InvitationLogsFilter from './Filters/InvitationLogsFilter';
import { InvitationLogFilters } from '../../../../services/api/invitationLogs';
import Filter from '../../../../filters/Filter';
import invitationLogsValidation from './Filters/invitationLogsValidation';

export const InvitationLogsFilters = (props: {
	onFiltersChange: (filters: InvitationLogFilters) => void;
}): React.ReactElement => {
	const { onFiltersChange } = props;

	const initFilters: InvitationLogFilters = {
		status: 0,
		message: '',
		createdTs: undefined,
	};

	return (
		<Filter
			onChange={onFiltersChange}
			initFilters={initFilters}
			validationSchema={invitationLogsValidation}
			CustomFilters={InvitationLogsFilter}
		/>
	);
};
