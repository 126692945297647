import React from 'react';
import { Table } from '@mui/material';
import InvitationLogBody from './InvitationLogBody/InvitationLogBody';
import InvitationLogHeader from './InvitationLogHeader/InvitationLogHeader';

export interface InvitationLogTableData {
	id: number;
	entityId: number;
	status: number;
	providerName: string | null;
	message: string;
	details: string;
	createdTs: string;
}

export type InvitationLogs = InvitationLogTableData[];

const InvitationLogTable = (props: { logs: InvitationLogs }): React.ReactElement => {
	const { logs } = props;
	return (
		<Table>
			<InvitationLogHeader />
			<InvitationLogBody logs={logs} />
		</Table>
	);
};

export default InvitationLogTable;
