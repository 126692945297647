import ReviewListFilter from './Filters/ReviewListFilter';
import { ReviewListFilterProps } from '../../../../services/api/reviews';
import Filter from '../../../../filters/Filter';
import ReviewFilterValidation from './Filters/reviewFilterValidation';

export const ReviewFilters = (props: { onFilterChange: (newFilters: ReviewListFilterProps) => void }) => {
	const { onFilterChange } = props;
	const initFilters: ReviewListFilterProps = {
		minRating: 0,
		hasContent: false,
		hasTitle: false,
		hasName: false,
		hasEmail: false,
		allowedSources: [],
		allowedStatus: [],
	};

	return (
		<Filter
			CustomFilters={ReviewListFilter}
			initFilters={initFilters}
			onChange={onFilterChange}
			validationSchema={ReviewFilterValidation}
		/>
	);
};
