import React from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { InvitationLogs, InvitationLogTableData } from '../InvitationLogTable';
import { transformDateToDisplayString } from '../../../../../utils/common/dateUtils';

export const InvitationLogBody = (props: { logs: InvitationLogs }): React.ReactElement => {
	const { logs } = props;
	return (
		<TableBody>
			{logs.map((log: InvitationLogTableData, index: number) => {
				return (
					<TableRow key={`log-row-${index}`}>
						<TableCell>{index + 1}</TableCell>
						<TableCell>{log.message}</TableCell>
						<TableCell>{log.details}</TableCell>
						<TableCell>{log.status}</TableCell>
						<TableCell>{log.providerName || 'UNKNOWN'}</TableCell>
						<InvitationLogDateCell date={log.createdTs} />
					</TableRow>
				);
			})}
		</TableBody>
	);
};

const InvitationLogDateCell = (props: { date: string }): React.ReactElement => {
	const { date } = props;
	if (!date) {
		return <TableCell>UNKNOWN</TableCell>;
	} else {
		const dateObj: Date = new Date(date);
		const formattedDate: string = transformDateToDisplayString(dateObj);
		return <TableCell>{formattedDate}</TableCell>;
	}
};

export default InvitationLogBody;
