import { useState } from 'react';
import { Table, Box } from '@mui/material';
import { ReviewListFilterProps } from '../../services/api/reviews';
import { ReviewTableHead } from './components/ReviewTableHead';
import { ReviewTableBody } from './components/ReviewTableBody';
import { Pagination } from '../Pagination';
import { usePost } from '../../utils/useFetch';
import { useSelector } from 'react-redux';
import { ReviewFilters } from './components/ReviewFilters';
import { Loading } from '../Global/components/Loading';

export const ReviewTable = () => {
	const entity = useSelector((state: any) => state.entity);

	const [filters, setFilters] = useState<ReviewListFilterProps>({});
	const [currentPage, setCurrentPage] = useState<number>(0);
	const [totalPages, setTotalPages] = useState<number>(0);
	const [rowsPerPage, setRowsPerPage] = useState<number>(20);

	const handleChangeFilters = (newFilters: ReviewListFilterProps): void => {
		setFilters({ ...newFilters });
		setCurrentPage(0);
	};

	const reviewsRequest = usePost(`/reviews`, true, [currentPage, rowsPerPage, entity, filters], {
		internalId: entity.id,
		paginationData: { page: currentPage, perPage: rowsPerPage },
		filters: filters,
	});

	return (
		<Box style={{ width: '100%' }}>
			<Box sx={{ background: '#d7d7d7', color: 'white' }}>
				<ReviewFilters onFilterChange={handleChangeFilters} />
			</Box>
			<Box sx={{ overflow: 'auto', height: 'calc(100vh - 216px)' }}>
				{!reviewsRequest.loading ? (
					<Table>
						<ReviewTableHead />
						<ReviewTableBody reviews={reviewsRequest.data.reviews} />
					</Table>
				) : (
					<Loading />
				)}
			</Box>
			<Pagination
				setTotalPages={setTotalPages}
				setRowsPerPage={setRowsPerPage}
				setCurrentPage={setCurrentPage}
				totalPages={reviewsRequest.data?.paginationData.totalPages || totalPages}
				currentPage={currentPage}
				rowsPerPage={rowsPerPage}
			/>
		</Box>
	);
};
