import { Box } from '@mui/material';
import PermissionsBar from './PermissionsBar';



const PermissionsList = ({permissions, searchCriteria, handlePermissionChange} : any) => {
	const generatePermissionsBars = (permissions: any, searchCriteria: any) => {
		let bars: any = [];
	
		// permissions.forEach((permission: any) => {
		// 	permission?.entities.forEach((entity: any) => {
		// 		bars.push({key: entity.id, entity: entity , permission: permission.permission});
		// 	});
		// });

		permissions.forEach((p:any) => {
			bars.push({key: p.entity.id, entity: p.entity , permission: p.permission, permGroup: p.permGroup})
		})
	
		let filteredBars = [];

		searchCriteria = searchCriteria.toLowerCase();
	
		if (searchCriteria) {
			filteredBars = bars.filter((bar: any) => {
				return ((bar.entity.name).toLowerCase()).includes(searchCriteria)
			});
		}
	
		if (!searchCriteria && filteredBars.length === 0) {
			filteredBars = bars;
		}
	
		let filteredBarComponents: any = [];
	
		filteredBars.forEach((fb: any) => {
			filteredBarComponents.push(<PermissionsBar key={fb.entity.id} entity={fb.entity} permGroup={fb.permGroup} permission={fb.permission} handlePermissionChange={handlePermissionChange} />);
		})
	
		return filteredBarComponents;
	};

	return (
		<Box sx={{ maxWidth: '100vw', display: 'flex', flexDirection: 'column', padding: '0 50px' }}>
			{generatePermissionsBars(permissions, searchCriteria)}
		</Box>
	)
};

export default PermissionsList;