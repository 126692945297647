import { client } from './client';

export interface IReviewSource {
	id: number;
	name: string;
	displayName: string | null;
	isActive: boolean;
}

const sourceUrl = '/sources';

export const getSources = async (): Promise<IReviewSource[]> => {
	return await client({
		url: sourceUrl,
		config: { method: 'GET' },
	});
};
