import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { logoutUser } from '../../../redux/userReducer';
import { Button } from '@mui/material';

export const LogoutButton = (props: { callback?: () => void }) => {
	const { callback } = props;
	const { logout } = useAuth0();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const handleClick = () => {
		try {
			dispatch(logoutUser());
			callback && callback();
			logout({ returnTo: window.location.origin });
		} catch (_error: any) {
			enqueueSnackbar('Logout Failed', { variant: 'error' });
		}
	};

	return (
		<Button sx={{ color: '#93DA49' }} onClick={handleClick}>
			Log Out
		</Button>
	);
};
