import { Button, Card, CardActionArea, CardActions, CardContent, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setEntity } from '../../../redux/entityReducer';
import { BUSINESS_ENTITY_TYPE } from '../../../utils/common/uuidConstants';

const EntityBar = ({entity}: any) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const setAndNavigate = (e: any, path: string, entity: any) => {
		e.preventDefault();
		e.stopPropagation();
		dispatch(setEntity(entity));
		navigate(path);
	}

	const getParentName = () => {
		const filtered = entity.ParentEntities.filter((parent: any) => parent.entityTypeId === BUSINESS_ENTITY_TYPE);
		return filtered[0]?.name ?? '';
	}

	return (
		<Card
			sx={{
				width: '100%',
				minHeight: '75px',
				justifyContent: 'space-between',
				margin: '5px',
				cursor: 'pointer',
				position: 'relative',
				display: 'flex'
			}}
			onClick={(e) => 
				setAndNavigate(e, `/entity/overview/${entity.id}`, entity)
			}
		>
			<CardActionArea>
				<CardContent sx={{ paddingTop: '10px', paddingBottom: '10px' }}>
					<Typography variant="h6" sx={{ alignSelf: 'flex-start' }}>
						{entity.name}
					</Typography>
					<Typography sx={{ alignSelf: 'flex-end', color: 'gray', fontSize: '10px' }}>
						{getParentName()}
					</Typography>
				</CardContent>
			</CardActionArea>

			<CardActions 
				sx={{
					position: 'absolute',
					bottom: 0,
					right: 0
				}}
			>
				<Button onClick={e => setAndNavigate(e, `/entity/reviews/`, entity)} variant="contained"color={'primary'} sx={{ alignSelf: 'flex-end', height: '25px', fontSize: '11px' }} size="small">
					View Reviews
				</Button>
				<Button onClick={e => setAndNavigate(e, `/entity/invitationLog/`, entity)} variant="contained" color={'primary'} sx={{ alignSelf: 'flex-end', height: '25px', fontSize: '11px' }} size="small">
					Invitation Log
				</Button>
				<Button onClick={e => setAndNavigate(e, `/entity/manageFeedback/`, entity)} variant="contained" color={'primary'} sx={{ alignSelf: 'flex-end', height: '25px', fontSize: '11px' }} size="small">
					Manage Feedback
				</Button>
				<Button onClick={e => setAndNavigate(e, `/entity/settings/`, entity)} variant="contained" color={'primary'} sx={{ alignSelf: 'flex-end', height: '25px', fontSize: '11px' }} size="small">
					Settings
				</Button>
			</CardActions>
		</Card>
	);
};

export default EntityBar;
