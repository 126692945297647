import { Routes, Route } from 'react-router-dom';
import { Auth } from '../modules/Global/components/Auth';
import { Login } from '../modules/Global/components/Login';
import Home from '../modules/Home';
import InvitationLogs from '../modules/InvitationLogs';
import { ReviewTable } from '../modules/Reviews';
import { ReviewDetails } from '../modules/ReviewDetails';
import FeedbackReviewTable from '../modules/FeedbackReviews';
import EntityOverview from '../modules/EntityOverview';
import ManageUsers from '../modules/ManageUsers';
import UserOverview from '../modules/UserOverview';
import EntitySettings from '../modules/EntitySettings/EntitySettings';
import EntityCredentials from '../modules/EntityCredentials';

export const AppRoutes = () => {
	return (
		<Routes>
			<Route path="/auth0" element={<Login />} />
			<Route path="/" element={<Auth />}>
				<Route index element={<Home />} />
				<Route path="/createOrg" element={<div>Create Root Org</div>} />
				<Route path="/manageUsers" element={<ManageUsers />} />

				<Route path="/entity/overview/:entityId" element={<EntityOverview />} />
				<Route path="/entity/user/:userId" element={<UserOverview />} />
				<Route path="/entity/reviews" element={<ReviewTable />} />
				<Route path="/entity/invitationLog" element={<InvitationLogs />} />
				<Route path="/entity/manageFeedback" element={<FeedbackReviewTable />} />
				<Route path="/entity/createEntity" element={<div>Create Entity</div>} />
				<Route path="/entity/settings" element={<EntitySettings />} />
				<Route path="/entity/credentials" element={<EntityCredentials />} />

				<Route path="/reviews/details/:entityId/:reviewId" element={<ReviewDetails />} />
			</Route>
		</Routes>
	);
};
