import React, { useEffect, useState } from 'react';
import { FormGroup } from '@mui/material';
import { getSources, IReviewSource } from '../../../../../services/api/sources';
import SelectorField from '../../../../../filters/Fields/SelectorField';
import MultiSelectField from '../../../../../filters/Fields/MultiSelectField';
import SourcesMultiSelect from '../../../../../filters/Fields/SourcesMultiSelect';

interface ISource {
	id: number;
	displayName: string;
}

const ratingOptions = [
	{ label: '0', value: '0' },
	{ label: '1', value: '1' },
	{ label: '2', value: '2' },
	{ label: '3', value: '3' },
	{ label: '4', value: '4' },
	{ label: '5', value: '5' },
];

const statusOptions = ['PUBLISHED', 'UNPUBLISHED', 'STORED', 'APPROVED'];

const ReviewListFilter = (props: { formik: any }): React.ReactElement => {
	const { formik } = props;
	const [sources, setSources] = useState<ISource[]>([]);

	useEffect(() => {
		fetchSources().then((response) => {
			setSources(response);
		});
	}, []);

	return (
		<FormGroup style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
			<SelectorField
				name={'minRating'}
				displayName={'Min. Rating'}
				options={ratingOptions}
				formik={formik}
				style={{ width: '90px' }}
			/>
			<MultiSelectField formik={formik} name={'allowedStatus'} displayName={'Statuses'} options={statusOptions} />
			<SourcesMultiSelect
				formik={formik}
				name={'allowedSources'}
				displayName={'Sources'}
				options={sources.map((option) => {
					return { value: option.id, label: option.displayName };
				})}
			/>
		</FormGroup>
	);
};

export default ReviewListFilter;

const fetchSources = async (): Promise<any[]> => {
	const sources = await getSources();
	return sources.map((source: IReviewSource): ISource => {
		return {
			id: source.id,
			displayName: source.displayName || source.name,
		};
	});
};
