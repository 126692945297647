import React from 'react';
import { MenuItem, Select, FormControl, InputLabel, OutlinedInput } from '@mui/material';

interface SelectorFieldOption {
	label: string;
	value: string;
}

type SelectorFieldOptions = SelectorFieldOption[];

const SelectorField = (props: {
	name: string;
	displayName: string;
	options: SelectorFieldOptions;
	formik: any;
	style: any;
}): React.ReactElement => {
	const { name, options, displayName, formik, style } = props;

	return (
			<FormControl>
				<InputLabel id="rating_label">{displayName}</InputLabel>
				<Select 
					input={<OutlinedInput label={displayName} />}
					value={formik.values[name]}
					onChange={formik.handleChange}
					labelId="rating_label"
					id="rating"
					name={name}
					sx={style}
				>	
					{options.map((option) => {
						return (
							<MenuItem key={`${name}-${option.value}`} value={option.value}>
								{option.label}
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>
	);
};

SelectorField.defaultProps = {
	style: {}
  }

export default SelectorField;
