import { createStore, combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

//import navBarReducer from './navBarSize';
import drawerReducer from './drawerReducer';
import entityReducer from './entityReducer';
import userReducer from './userReducer';

//Global State

const persistConfig = {
	key: 'root',
	storage,
}

const persistedReducer = persistReducer(persistConfig, combineReducers({drawer: drawerReducer, user: userReducer, entity: entityReducer}))

export const configureStore = () => {
	let store = createStore(persistedReducer)
	let persistor = persistStore(store)
	return { store, persistor }
}


