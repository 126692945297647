import React from 'react';

const REACT_APP_AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
const REACT_APP_AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
const REACT_APP_AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;
const REACT_APP_AUTH0_SECRET = process.env.REACT_APP_AUTH0_SECRET;
const REACT_APP_SITE_URL = process.env.REACT_APP_SITE_URL;

interface DotEnv {
	domain: string;
	clientId: string;
	audience: string;
	site: string;
	secret: string;
}

const isEnvValid =
	REACT_APP_AUTH0_DOMAIN &&
	REACT_APP_AUTH0_CLIENT_ID &&
	REACT_APP_AUTH0_AUDIENCE &&
	REACT_APP_AUTH0_SECRET &&
	REACT_APP_SITE_URL;

if (!isEnvValid) {
	throw new Error('Missing environment variables.');
}

const dotenv: DotEnv = {
	domain: REACT_APP_AUTH0_DOMAIN,
	clientId: REACT_APP_AUTH0_CLIENT_ID,
	audience: REACT_APP_AUTH0_AUDIENCE,
	site: REACT_APP_SITE_URL,
	secret: REACT_APP_AUTH0_SECRET,
};

export const EnvContext: React.Context<DotEnv> = React.createContext(dotenv);

export const useEnv = () => {
	const context = React.useContext(EnvContext);
	if (!context) {
		throw new Error(`useEnv must be used within a EnvProvider`);
	}
	return context;
};

export const EnvProvider = (props: any) => {
	return <EnvContext.Provider value={dotenv} {...props} />;
};
