import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Select, MenuItem, Typography, Box } from '@mui/material';

import { useSelector } from 'react-redux';

import { usePost } from '../../../utils/useFetch';
import EntitySelect from './EntitySelect';

const CreatePermissionDialog = ({
	open,
	setOpen,
	submitUser,
	setNewPermission,
	setNewEntity,
	newPermission,
	newEntity,
}: any): React.ReactElement => {
	const user = useSelector((state: any) => state.user);

	const evalClose = (reason: string) => {
		if (reason === 'backdropClick') return;
		setOpen(false)
	}

	const entitiesRequest = usePost(`/entities`, true, [], { internalId: user.internalId });

	return !entitiesRequest.loading ? (
		entitiesRequest.success ? (
			<Dialog open={open} onClose={(e, reason) => evalClose(reason)}>
				<DialogTitle>Add Permission</DialogTitle>
				<DialogContent>
					<EntitySelect newEntity={newEntity} setNewEntity={setNewEntity} entityList={entitiesRequest.data} />
					<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
						<Typography variant="body2" sx={{ alignSelf: 'center', marginRight: '10px' }}>
							Permissions Level:
						</Typography>
						<Select
							sx={{ alignSelf: 'flex-end', width: '150px', height: '35px', fontSize: '12px' }}
							value={newPermission}
							onChange={(e) => setNewPermission(e.target.value)}
							MenuProps={{
								disableScrollLock: true,
							}}
						>
							<MenuItem value={'read'}>Read</MenuItem>
							<MenuItem value={'write'}>Write</MenuItem>
						</Select>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpen(false)}>Cancel</Button>
					<Button disabled={!newEntity} variant='contained' color='primary' onClick={submitUser}>Create</Button>
				</DialogActions>
			</Dialog>
		) : (
			<>API Data Population Failure</>
		)
	) : (
		<></>
	);
};

export default CreatePermissionDialog;
