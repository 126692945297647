//Node Modules
import { useState } from 'react';
import { useParams } from 'react-router-dom';

//Material
import { Box } from '@mui/material';

//Internal
import PermissionsSearchBar from './components/PermissionsSearchBar';
import PermissionsList from './components/PermissionsList';
import { usePost } from '../../utils/useFetch';
import { Loading } from '../Global/components/Loading';
import PermissionActions from './components/PermissionActions';
import PermissionSubmission from './components/PermissionSubmission';
import CreatePermissionDialog from './components/CreatePermissionDialog';
import NewPermissionSubmission from './components/NewPermissionSubmission';

const UserOverview = () => {
	const params = useParams();
	const { userId } = params;

	const [pendingChanges, setPendingChanges] = useState<any[]>([]);
	const [searchCriteria, setsearchCriteria] = useState<string>('');
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [reRender, setReRender] = useState<boolean>(false);
	const [submittingCreation, setSubmittingCreation] = useState<Boolean>(false);

	const [open, setOpen] = useState(false);

	const [newEntity, setNewEntity] = useState<string>('');
	const [newPermission, setNewPermission] = useState<string>('read');

	const handlePermissionChange = (modifiedPermission: any) => {
		if (modifiedPermission.value !== modifiedPermission.permission) {
			if (!pendingChanges.some(e => e.entity.id === modifiedPermission.entity.id)) {
				console.log('Item Doesnt Already Exist As Edit');
				setPendingChanges(oldArray => [...oldArray, modifiedPermission])
			} else {
				console.log('Item Already Exists As Edit');
			}
		} else {
			const updatedChanges = pendingChanges.filter(change => {
				if (change.entity.id === modifiedPermission.entity.id) {
					return false;
				}

				return true;
			})

			setPendingChanges(updatedChanges);
		}
	}

	const resetComponent = () => {
		setSubmitting(false);
		setSubmittingCreation(false);
		setPendingChanges([]);
		setReRender(!reRender);
	}

	const submitNewPermission = () => {
		setOpen(false);
		setSubmittingCreation(true);
	};

	const userRequest = usePost(`/entities/user`, true, [reRender], { userId: userId });

	return !userRequest.loading ? (
		<>
			{submitting ? (
				<PermissionSubmission
					userId={userId}
					pendingChanges={pendingChanges}
					resetComponent={resetComponent}
				/>
			) : null}
			{submittingCreation ? (
				<NewPermissionSubmission resetComponent={resetComponent} user={userId} entityId={newEntity} permission={newPermission} />
			) : null}
		<Box sx={{display: 'flex', flexDirection: 'column', flex: '1'}}>
			<PermissionsSearchBar user={userRequest.data.entity} setsearchCriteria={setsearchCriteria}/>
			<PermissionsList permissions={userRequest.data.permissions} searchCriteria={searchCriteria} handlePermissionChange={handlePermissionChange} />
		</Box>
		<CreatePermissionDialog
					open={open}
					setOpen={setOpen}
					setNewEntity={setNewEntity}
					setNewPermission={setNewPermission}
					newPermission={newPermission}
					newEntity={newEntity}
					submitUser={submitNewPermission}
		/>
		<PermissionActions createPermission={() => setOpen(true)} pendingChanges={pendingChanges} setSubmitting={setSubmitting} resetComponent={resetComponent}/>
		</>
	) : <Loading />;
};

export default UserOverview;