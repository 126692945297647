import { Snackbar, Alert } from '@mui/material';
import React from 'react';
import { usePost } from '../../../utils/useFetch';

const PermissionSubmission = (props : any): React.ReactElement => {
	const { pendingChanges, resetComponent, userId } = props;

	const settingsUpdateRequest = usePost('/entities/updatePermissions', true, null, { userId: userId, changes: pendingChanges });

	return !settingsUpdateRequest.loading ? (
		<Snackbar
			open={true}
			autoHideDuration={1000}
			onClose={resetComponent}
			message={`Updated Successfully`}
		>
			<Alert onClose={resetComponent} severity="success" sx={{ width: '100%' }}>
				This is a success message!
			</Alert>
		</Snackbar>
	) : (
		<></>
	);
};

export default PermissionSubmission;
