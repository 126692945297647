import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import { usePost } from '../../../utils/useFetch';
import { useSelector } from 'react-redux';

const ManageUserSubmission = ({ resetComponent, name, email }: any): React.ReactElement => {
	const user = useSelector((state: any) => state.user);

	const settingsUpdateRequest = usePost('/entities/createUser', true, null, {
		internalId: user.internalId,
		name: name,
		email: email,
	});

	return !settingsUpdateRequest.loading ? (
		<Snackbar open={true} autoHideDuration={1000} onClose={resetComponent} message={`Created Successfully`}>
			<Alert onClose={resetComponent} severity="success" sx={{ width: '100%' }}>
				Created Successfully
			</Alert>
		</Snackbar>
	) : (
		<></>
	);
};

export default ManageUserSubmission;
