import React from 'react';
import Filter from '../../../../filters/Filter';
import FeedbackListFilter from './Filters/FeedbackListFilter';
import { FeedbacksFilterProps } from '../../../../services/api/feedbacks';
import feedbackFilterValidationSchema from './Filters/feedbackFilterValidation';

export const FeedbackReviewFilters = (props: { onFilterChange: (newFilters: FeedbacksFilterProps) => void }) => {
	const initFilters: FeedbacksFilterProps = {
		authorEmail: '',
		reviewDate: '',
		minRating: 0,
		allowedStatus: [],
	};
	return (
		<Filter
			onChange={props.onFilterChange}
			initFilters={initFilters}
			validationSchema={feedbackFilterValidationSchema}
			CustomFilters={FeedbackListFilter}
		/>
	);
};
