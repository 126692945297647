export function debounce(func: (...args: any[]) => any, timeout: number = 1000): (...args: any[]) => void {
	let timer: NodeJS.Timeout;

	return (...args: any[]) => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			func(...args);
		}, timeout);
	};
}
