import { Button, Card, CardActionArea, CardActions, CardContent, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";

const User = ({entity, deleteUser}: any) => {
	const navigate = useNavigate();

	return (
		<Card
			sx={{
				width: '100%',
				minHeight: '75px',
				justifyContent: 'space-between',
				margin: '5px',
				cursor: 'pointer',
				position: 'relative',
				display: 'flex'
			}}
			onClick={(e) => 
				navigate(`/entity/user/${entity.id}`)
			}
		>
			<CardActionArea>
				<CardContent sx={{ paddingTop: '10px', paddingBottom: '10px' }}>
					<Typography variant="h6" sx={{ alignSelf: 'flex-start' }}>
						{entity.name}
					</Typography>
					<Typography sx={{ alignSelf: 'flex-end', color: 'gray', fontSize: '10px' }}>
						id: {entity.id}
					</Typography>
				</CardContent>
			</CardActionArea>

			<CardActions 
				sx={{
					position: 'absolute',
					bottom: 0,
					right: 0
				}}
			>
				<Button onClick={(e) => {console.log(entity); deleteUser(e, entity.name, entity.id)}} variant="contained" color={'error'} sx={{ alignSelf: 'flex-end', height: '25px', fontSize: '11px' }} size="small">
					Delete User
				</Button>
			</CardActions>
		</Card>
	);
};

export default User;
