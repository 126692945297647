import { KVPOption, StaticField } from '../types';

const STATIC_SETTINGS = ['FacilityName', 'Phone', 'Street', 'City', 'State', 'Zip'];

export const addStaticFields = (options: KVPOption[], staticKeys: string[] = STATIC_SETTINGS): StaticField[] => {
	const result: StaticField[] = [...options];
	staticKeys.forEach((staticKey: string) => {
		const matched = options.find((o) => o.key === staticKey);
		if (!matched) {
			result.push({ key: staticKey, value: null });
		}
	});

	return result;
};
