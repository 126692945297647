import { Avatar } from '@mui/material';
import { useState } from 'react';
import { Box, Modal } from '@mui/material';
import { LogoutButton } from './LogoutButton';
import { useAuth0 } from '@auth0/auth0-react';

export const UserAvatar = () => {
	const auth0Data = useAuth0();
	const auth0User = auth0Data.user;
	const [userModal, setUserModal] = useState<boolean>(false);

	const logOutCallback = () => {
		setUserModal(false);
	};

	return auth0User ? (
		<Box sx={{ position: 'relative' }}>
			<Avatar
				onClick={(e) => {
					e.preventDefault();
					setUserModal(!userModal);
				}}
				src={auth0User && auth0User.picture}
				style={{ cursor: 'pointer' }}
				imgProps={{ referrerPolicy: 'no-referrer' }}
				alt="Logout"
			/>
			<Modal open={userModal} onClose={() => setUserModal(!userModal)}>
				<Box
					sx={{
						position: 'absolute',
						top: '55px',
						right: '25px',
						background: '#161616',
						color: 'white',
						borderRadius: '5px',
					}}
				>
					<LogoutButton callback={logOutCallback} />
				</Box>
			</Modal>
		</Box>
	) : null;
};
