//Material UI
import { CircularProgress, Box } from '@mui/material';

export const Loading = (props: { info?: boolean }) => {
	const { info } = props;
	return (
		<Box
			sx={{
				height: 'calc(100vh - 192px)',
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				zIndex: '10'
			}}
		>
			<CircularProgress color={info ? 'info' : 'primary'} />
		</Box>
	);
};
