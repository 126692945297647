import React from 'react';
import { IReview } from '../../../services/api/reviews';
import { TableCell, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';
import { transformDateToDisplayString } from '../../../utils/common/dateUtils';

export const Review = (props: { review: IReview; index: number }): React.ReactElement => {
	const { review, index } = props;
	return (
		<TableRow>
			<TableCell>
				<Link
					to={{
						pathname: `/reviews/details/${review.entityId}/${review.reviewId}`,
					}}
				>
					{index}
				</Link>
			</TableCell>
			<ReviewerNameCell name={review.reviewer} />
			<RatingValueCell ratingValue={review.rating} />
			<ReviewSourceCell source={review.reviewSource} />
			<ReviewDateCell date={review.reviewDate} />
			<ReviewContentCell content={review.reviewText} />
		</TableRow>
	);
};

const ReviewerNameCell = (props: { name: string }): React.ReactElement => {
	const { name } = props;
	return <TableCell>{name}</TableCell>;
};

const RatingValueCell = (props: { ratingValue: number }): React.ReactElement => {
	const { ratingValue } = props;
	return <TableCell>{ratingValue || 'NOT RATED'}</TableCell>; // If ratingValue is 0, NOT RATED will be rendered.
};

const ReviewSourceCell = (props: { source: string | null }): React.ReactElement => {
	const { source } = props;
	return <TableCell>{source || 'SOURCE UNDEFINED'}</TableCell>;
};

const ReviewDateCell = (props: { date: string | null }): React.ReactElement => {
	const { date } = props;
	let element: React.ReactElement;
	if (!date) {
		element = <TableCell>UNKNOWN</TableCell>;
	} else {
		const dateObj: Date = new Date(date);
		const formattedDate = transformDateToDisplayString(dateObj);
		element = <TableCell>{formattedDate}</TableCell>;
	}
	return element;
};

const ReviewContentCell = (props: { content: string | null }): React.ReactElement => {
	const { content } = props;
	const textLen: number = 50;
	let text: string;
	if (!content) {
		text = 'NO CONTENT';
	} else {
		text = content.substring(0, textLen);
	}
	return (
		<TableCell>
			{text}
			{content && content.length > textLen ? '...' : ''}
		</TableCell>
	);
};
