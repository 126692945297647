import React, { useState } from 'react';
import { InvitationLogFilters, InvitationLogResponse } from '../../../services/api/invitationLogs';
import InvitationLogTable from './InvitationLogTable/InvitationLogTable';
import { Pagination } from '../../Pagination';
import { InvitationLogsFilters } from './InvitationLogsFilters';
import { usePost } from '../../../utils/useFetch';
import { Loading } from '../../Global/components/Loading';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

const InvitationLogList = (): React.ReactElement => {
	const entity = useSelector((state: any) => state.entity);
	const [filters, setFilters] = useState<InvitationLogFilters>({});
	const [currentPage, setCurrentPage] = useState<number>(0);
	const [rowsPerPage, setRowsPerPage] = useState<number>(20);
	const [totalPages, setTotalPages] = useState<number>(0);

	const handleChangeFilters = (newFilters: InvitationLogFilters): void => {
		setFilters({ ...newFilters });
		setCurrentPage(0);
	};

	const getInvitationLogsResponse = usePost<InvitationLogResponse>(
		'/invitation-logs',
		true,
		[currentPage, rowsPerPage, filters],
		{
			entityId: entity.id,
			paginationData: { page: currentPage, perPage: rowsPerPage },
			filters: filters,
		},
	);

	return (
		<Box style={{ width: '100%' }}>
			<Box sx={{ background: '#d7d7d7', color: 'white' }}>
				<InvitationLogsFilters onFiltersChange={handleChangeFilters} />
			</Box>
			<Box sx={{ overflow: 'auto', height: 'calc(100vh - 216px)' }}>
				{!getInvitationLogsResponse.loading ? (
					getInvitationLogsResponse.success ? (
						<InvitationLogTable logs={getInvitationLogsResponse.data.data} />
					) : (
						<>API Data Population Failure</>
					)
				) : (
					<Loading />
				)}
			</Box>
			<Pagination
				setTotalPages={setTotalPages}
				setRowsPerPage={setRowsPerPage}
				setCurrentPage={setCurrentPage}
				totalPages={getInvitationLogsResponse.data?.paginatorData.totalPages || totalPages}
				currentPage={
					getInvitationLogsResponse.data ? getInvitationLogsResponse.data.paginatorData.currentPage : currentPage
				}
				rowsPerPage={
					getInvitationLogsResponse.data ? getInvitationLogsResponse.data.paginatorData.itemsForPage : rowsPerPage
				}
			/>
		</Box>
	);
};

export default InvitationLogList;
