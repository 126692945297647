import { Box, Typography, Select, MenuItem } from '@mui/material';

const EntitySelect = ({ newEntity, setNewEntity, entityList }: any): React.ReactElement => {

	const buildMenuItems = (entityList: any) => {
		const sorted = entityList.sort((a: any, b: any) => a.name.localeCompare(b.name))
		return sorted.map((entity: any) => {
			return <MenuItem key={entity.id} value={entity.id}>{entity.name}</MenuItem>;
		});
	}

	return (
		<Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
			<Typography variant="body2" sx={{ alignSelf: 'center', marginRight: '10px' }}>
				Entity:
			</Typography>
			<Select
				sx={{ alignSelf: 'flex-end', width: '225px', height: '35px', fontSize: '12px' }}
				value={newEntity}
				onChange={(e) => setNewEntity(e.target.value)}
				MenuProps={{
					disableScrollLock: true,
				}}
			>
				{buildMenuItems(entityList)}
			</Select>
		</Box>
	);
};

export default EntitySelect;
