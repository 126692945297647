import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import { ReviewDetailsRows } from './types';

export const ReviewDetailsRow = (props: ReviewDetailsRows) => {
	const { rowName, value } = props;

	return (
		<TableRow>
			<TableCell>{rowName}</TableCell>
			<TableCell>{value}</TableCell>
		</TableRow>
	);
};
