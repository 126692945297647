import { Snackbar, Alert } from '@mui/material';
import React from 'react';
import { usePost } from '../../../utils/useFetch';
import { useSelector } from 'react-redux';

const DeleteUserSubmission = ({resetComponent, deleteUserId} : any): React.ReactElement => {
	const user = useSelector((state: any) => state.user);

	const settingsUpdateRequest = usePost('/entities/deleteUser', true, null, {internalId: user.internalId, deleteUserId: deleteUserId});

	return !settingsUpdateRequest.loading ? (
		<Snackbar
			open={true}
			autoHideDuration={1000}
			onClose={resetComponent}
		>
			<Alert onClose={resetComponent} severity="success" sx={{ width: '100%' }}>
				Deleted Successfully
			</Alert>
		</Snackbar>
	) : (
		<></>
	);
};

export default DeleteUserSubmission;
