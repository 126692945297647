import React, { useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';

interface MultiSelectOption {
	value: string | number;
	label: string;
}

const MultiSelectField = (props: {
	formik: any;
	name: string;
	displayName: string;
	options: Array<string | { value: string | number; label: string }>;
}): React.ReactElement => {
	const { formik, name, displayName, options } = props;
	const [selects, setSelects] = React.useState<Array<string | number>>([]);

	const handleChange = (event: SelectChangeEvent<typeof selects>) => {
		const value = event.target.value;
		setSelects(typeof value === 'string' ? value.split(',') : value);
	};

	const normalizedOptions: MultiSelectOption[] = options.map((option) => {
		if (typeof option === 'string') {
			return { value: option, label: option };
		} else {
			return option;
		}
	});

	useEffect(() => {
		const values = selects.map((value) => normalizedOptions.find((i) => i.value === value));
		const arr: Array<string | number> = [];
		values.forEach((item) => (item ? arr.push(item.value) : undefined));
		formik.values[name] = selects;
		// eslint-disable-next-line
	}, [selects]);

	return (
		<div>
			<FormControl sx={{ m: 1, width: 300 }}>
				<InputLabel>{displayName}</InputLabel>
				<Select multiple value={selects} onChange={handleChange} input={<OutlinedInput label={displayName} />}>
					{normalizedOptions.map((option) => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

export default MultiSelectField;
