import AddIcon from '@mui/icons-material/Add';
import { Fab } from '@mui/material';

const RootEntityOverviewActions = ({createEntity} : any): React.ReactElement => {
	return (
		<Fab onClick={createEntity} sx={{ position: 'fixed', bottom: '20px', right: '20px' }} color="primary" aria-label="edit">
			<AddIcon />
		</Fab>
	);
};

export default RootEntityOverviewActions;
